import 'date-fns';
import React from 'react';
import Grid from '@material-ui/core/Grid';
import esLocale from "date-fns/locale/es";
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

export default function InputFecha(props) {
  // The first commit of Material-UI
  const [selectedDate, setSelectedDate] = React.useState((props.defaultValue == '' ? null : new Date(props.defaultValue)))

  const handleDateChange = (date) => {
    setSelectedDate(date);
    if(date != null && !isNaN(date.getTime())){
      props.setValue(date.toISOString().split('T')[0])
    }else{
      props.setValue('')
    }
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}  locale={esLocale}>
       <KeyboardDatePicker
          autoOk
          //disabled
          variant="inline"
          inputVariant="outlined"
          label={props.label}
          format="dd/MM/yyyy"
          value={selectedDate}
          className="calendar input_dark"
          color="secondary"
          InputAdornmentProps={{ position: "end", className: 'coloricon' }}
          onChange={date => handleDateChange(date)}
          variant="outlined"
          margin="normal"
          fullWidth
          color="primary"
          required = {props.required}
        />
    </MuiPickersUtilsProvider>
  );
}
