import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles((theme) => ({
  formControl: {
    // margin: theme.spacing(1),

    // minWidth: 120,
    // maxWidth: 300,
  },
  // chips: {
  //   display: 'flex',
  //   flexWrap: 'wrap',
  // },
  // chip: {
  //   margin: 2,
  // },
  // noLabel: {
  //   marginTop: theme.spacing(3),
  // },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: '100%',
    },
  },
};

export default function SimpleSelect(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [Valorname, setValorname] = React.useState(props.defaultValue);
  //const names = this.props.names;
  const handleChange = (event) => {
    setValorname(event.target.value);
    props.setValue(event.target.value)
  };
  return (
    <div>
      <FormControl variant="outlined" className={classes.formControl} margin="normal"
          fullWidth
          className="input_dark"
          color="secondary">
      <InputLabel id={props.idLabel}
      // margin={(props.dense)?'dense':'none'}
      >{props.label}</InputLabel>
        <Select
          labelId={props.idLabel}
          id={props.id}
          value={Valorname}
          onChange={handleChange}
          label={props.label}
          // margin={(props.dense)?'dense':'none'}
          margin="normal"
          fullWidth
          className="input_dark"
          color="secondary"
          required = {props.required}
        >
          <MenuItem value="" disabled>
            <em>Ninguno</em>
          </MenuItem>
          {props.opciones.map((opcion) => (
            <MenuItem key={opcion.value} value={opcion.value}>
              {opcion.text}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
