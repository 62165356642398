import axios from 'axios';
const ApiToken = {
	data : {
		uri : 'https://obelisco.es/obelisco-usuarios/public',
		token : {
			access : {
				key : 'X-API-TOKEN',
				value : null
			},
			remember : {
				key : 'X-REMEMBER-TOKEN',
				value : null
			},
		},
	},
	saveData(){
		for(let prop in this.data.token){
			localStorage.setItem(`token-${prop}`, this.data.token[prop].value)
		}
	},
	async getData(){
		for(let prop in this.data.token){
			if(localStorage.hasOwnProperty(`token-${prop}`) && localStorage.getItem(`token-${prop}`) != null){
				this.data.token[prop].value = localStorage.getItem(`token-${prop}`)
			}
		}
	},
	setToken (_token){
		for(let prop in this.data.token){
			if(_token.hasOwnProperty(this.data.token[prop].key)){
				this.data.token[prop].value = _token[this.data.token[prop].key]
			}
		}
		this.saveData()
	},
	setUser(_user){
		localStorage.setItem(`data-user`, _user)
	},
	getUser(){
		if(localStorage.hasOwnProperty(`data-user`))
			return localStorage.setItem(`data-user`)
		return null
	},
	async request(_method, _ruote, _data, _callback){
		await this.getData()
		let headers = {'Accept' : 'application/json, text/plain'}
		for(let prop in this.data.token){
			headers[this.data.token[prop].key] = this.data.token[prop].value
		}
		axios({
			method: _method,
			url: `${this.data.uri}/api/${_ruote}`,
			headers: {...headers},
			data: {..._data}
		}).then(res => {
			let __data = res.data
			let token = JSON.parse(atob(__data.___Token).split('').reverse().join('') )
			this.setToken(token)
			delete __data.___Token
			_callback(__data)
		}).catch(e => console.error(e))

		// fetch( `${this.data.uri}/api/${_ruote}`, {
		// 	  method: 'POST', // or 'PUT'
		// 	  body: _data, // data can be `string` or {object}!
		// 	  headers: headers
		// }).then(response => {
		// 		return response.json()
		// }).then(_res => {
		// 		let token = JSON.parse(atob(_res.___Token).split('').reverse().join('') )
		// 		delete _res.___Token
		// 		_callback(_res)
		// }).catch(e => console.error(e));
	},
	methods : {
		ValToUpperCase : function($this){
			return $this.value.toUpperCase();
		},
		ValToInt : function($this){
			return $this.value.replace(/[^0-9]/g,'')
		},
		ValToFloat : function($this){
			var new_val = $this.value.replace(/[^0-9 .]/g,'')
			var array = new_val.split('.')
			if(array.length>2){
				new_val = array[0]
				array.splice(0,1)
				new_val += '.'+array.join('')
			}
			return new_val
		},
		ValToNumber : function($this){
			return $this.value.replace(/[^0-9 -/]/g,'')
		}
	}
}

export default ApiToken
