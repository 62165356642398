import React from 'react';
import {__Uri, COLORS } from "../general/Constants";
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Avatar from '@material-ui/core/Avatar';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Logo from '../../assets/img/logo_text.svg'
import MeetingRoomSharpIcon from '@material-ui/icons/MeetingRoomSharp';
import Swal from 'sweetalert2'
// import imgavatar from '../../assets/img/avatar.png'
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import ApiToken from '../../util/ApiToken';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    position: 'sticky',
    top: '0',
    zIndex: '3',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    textAlign: 'center',

  },
  logoimg:{
    height: '35px',
    width: 'auto'
  }
}));

export default function MenuAppBar(props) {
  const classes = useStyles();
  const [auth, setAuth] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleChange = (event) => {
    setAuth(event.target.checked);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const _fetchLogout = () => {
    handleClose()
    Swal.fire({
      text: '¿Desea cerrar sesión?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: `Aceptar`,
      cancelButtonText: `Cancelar`,
      customClass:{
          popup:'bg_dark',
          confirmButton: 'btn_success',
          denyButton:'btn_deny',
          cancelButton:'btn_cancel',
          htmlContainer:'text_white',
          title:'text_white',
      }
    }).then((result) => {
        if (result.isConfirmed) {
          ApiToken.request('post','logout',{}, function(res){
            if(res.response){
              props.onSetLogout()
            }else{
              Swal.fire({
                text: '¿Desea cerrar sesión?',
                icon: 'error',
                confirmButtonText: `OK`,
                customClass:{
                    popup:'bg_dark',
                    confirmButton: 'btn_success',
                }
              })
            }
          })
        }
    })
  }

  const handleClose = () => {
    setAnchorEl(null);
  };
  var logopos ={
    position: 'relative',
    zIndex: 2,
    top: '10px',
    right: '-26px'
  }
  return (
    <div className={classes.root} >
      <AppBar position="static">
        <Toolbar style={{height:'40px', minHeight:'40px'}}>
          <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
            {/* <MenuIcon /> */}
          </IconButton>
          <Link className={classes.title} component={RouterLink} to={__Uri.home+ '/'}>
              <img src={__Uri.home + Logo} className={classes.logoimg}></img>
          </Link>
          {auth && (
            <div>
              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <Avatar alt="Remy Sharp"
                // src={__Uri.home + imgavatar}
                style={{height:'24px', width:'24px'}}/>
                <ExpandMoreIcon fontSize="small"></ExpandMoreIcon>
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={open}
                onClose={handleClose}
              >
                <MenuItem onClick={handleClose}>Perfil</MenuItem>
                <MenuItem onClick={handleClose}>Option</MenuItem>
                <MenuItem style={{color:COLORS.text_gris}} onClick={_fetchLogout}>
                  <MeetingRoomSharpIcon style={{color:COLORS.text_danger}}/>
                  Salir
                </MenuItem>
              </Menu>
            </div>
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
}
