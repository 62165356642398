import React from 'react';
import { COLORS, __Uri} from '../../components/general/Constants';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import Titulo from '../../components/general/Titulo'
import PermIdentityIcon from '@material-ui/icons/PermIdentity'
import Subtitulo from '../../components/general/Subtitulo'
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import VerLogs from '../../components/general/Logs';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import ButtonGroup from '@material-ui/core/ButtonGroup';

import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import BtnAdd from '../../components/forms/BtnAdd'

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import ApiToken from '../../util/ApiToken';
import { withRouter } from 'react-router-dom';
import Swal from 'sweetalert2'

const filter = createFilterOptions();
class AdminAsignacionPerm extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
          super : this.props.match.params.hasOwnProperty('id') ? this.props.match.params.id : null,
          item : this.props.match.params.hasOwnProperty('item') ? this.props.match.params.item : null,
          path : this.props.match.params.hasOwnProperty('path') ? this.props.match.params.path : null,
          lista : [],
          item_data : null,
          view: false,
      };
    }
    componentWillMount = () => {
      let _Self = this
      ApiToken.request('put','sistema/asignacion/edit',{
        keyID : this.state.super,
        itemID : this.state.item,
        path : this.state.path,
      }, function(res){
        if(res.response){
          _Self.setState({lista : [...res.data]})
          _Self.setState({item_data : res.item})
          _Self.setState({view : true})
        }else{
          Swal.fire({
            text: res.message,
            icon: 'error',
            confirmButtonText: `OK`,
            customClass:{
                popup:'bg_dark',
                confirmButton: 'btn_success',
            }
          }).then((result) => {
            _Self.props.history.replace(`/sistema/${this.state.super}/asignacion`)
          })
        }
      })
    }
    _fetchCancel = () => {
      this.props.history.replace(`/sistema/${this.state.super}/asignacion`)
    }
    _fetchChangeSubitemState = (_x, _y, _id, _newvalue) => {
      let temp = [...this.state.lista]
      if(temp[_x].list[_y].id == _id){
        temp[_x].list[_y].active = parseInt(_newvalue)
      }
      this.setState({lista : temp})
    }
    _fetchSaveRequest = () => {
      let _Self = this
      ApiToken.request('post','sistema/asignacion/update',{
        keyID : this.state.super,
        itemID : this.state.item,
        path : this.state.path,
        lista : this.state.lista,
      }, function(res){
        if(res.response){
          Swal.fire({
            text: res.message,
            icon: 'success',
            confirmButtonText: `OK`,
            customClass:{
                popup:'bg_dark',
                confirmButton: 'btn_success',
            }
          })
        }else{
          Swal.fire({
            text: res.message,
            icon: 'erro',
            confirmButtonText: `OK`,
            customClass:{
                popup:'bg_dark',
                confirmButton: 'btn_success',
            }
          }).then((result) => {
            _Self.props.history.replace(`/sistema/${this.state.super}/asignacion`)
          })
        }
      })
    }

    render(){
          const darkTheme = createMuiTheme({
            palette: {
              type: 'dark',
              secondary: {
                light: '#ffffff',
                main: '#788ba5',
                dark: '#546e91',
                contrastText: '#fff',
              }
            },
            secondary: {
                light: '#ffffff',
                main: '#788ba5',
                dark: '#546e91',
                contrastText: '#fff',
              }
          });
          const lista= {
            flexGrow: 1,
            overflow: 'hidden',
            padding: '10px 0',
          };
          const listSection= {
            backgroundColor: 'inherit',
          };
          const ul= {
            backgroundColor: 'inherit',
            padding: 0,
          };
          function RadioYes(props) {
            return (
                <Radio
                  disableRipple
                  color="default"
                  style={{border:'1px solid rgba(255, 255, 255, 0.23)', width:'32px', height:'32px', borderRadius:'4px', padding:'0'}}
                  checkedIcon={<CheckIcon style={{color: COLORS.text_success, background: 'rgba(255, 255, 255, 0.15)',width: '100%', height: '100%'}}/> }
                  icon={<CheckIcon style={{color: COLORS.text_success}}/>}
                  {...props}
                />
            );
          }
          function RadioNeutro(props) {
            return (
              <Radio
                disableRipple
                color="default"
                style={{border:'1px solid rgba(255, 255, 255, 0.23)', width:'32px', height:'32px', borderRadius:'0', padding:'0'}}
                checkedIcon={<span style={{ width:'100%', background: 'rgba(255, 255, 255, 0.15)', lineHeight:'32px'}} >/</span> }
                icon={<span >/</span>}
                {...props}
              />
            );
          }
          function RadioNo(props) {
            return (
                <Radio
                  disableRipple
                  color="default"
                  style={{border:'1px solid rgba(255, 255, 255, 0.23)', width:'32px', height:'32px', borderRadius:'4px', padding:'0'}}
                  checkedIcon={<CloseIcon style={{color: COLORS.text_danger, background: 'rgba(255, 255, 255, 0.15)',width: '100%', height: '100%'}}/> }
                  icon={<CloseIcon style={{color: COLORS.text_danger}}/> }
                  {...props}
                />
            );
          }
        return this.state.view && (
            <div style={{ padding:'0 10px'}}>
                <div className="contenido max_360">
                    <Button style={{float:'right', top: '-32px'}} onClick={(e)=>{this._fetchCancel()}}>
                      <HighlightOffIcon style={{fontSize:'32px'}}/>
                    </Button>
                    <Titulo text={`Permisos - ${this.state.item_data.nombre}`}/>
                    <form>
                        <ThemeProvider theme={darkTheme}>
                            <section className="section_form">
                                <div id="title_section-2">
                                    <PermIdentityIcon style={{ fontSize: 16, color:'#cccccc' }} className="inline" />
                                    <Subtitulo uppercase text="Permisos Avanzados"/>
                                </div>
                              <List  subheader={<li />}>
                                  <li key={`section-A`} className={listSection}>
                                    <ul className={ul}>
                                    {
                                      this.state.lista.map((item,index) => (
                                        <div key={`item-${index}`}>
                                          <ListSubheader>{item.title}</ListSubheader>
                                          {
                                            item.list.map((subitem, _index) => (
                                              <ListItem key={`item-rol-${subitem.id}`}>
                                                <ListItemText primary={subitem.permiso} style={{maxWidth:'72%'}}/>
                                                <ListItemSecondaryAction>
                                                  <FormControl  key={`item-form-${subitem.id}`} component="fieldset">
                                                    <RadioGroup row value={`${subitem.active}`} onChange={(e)=>{this._fetchChangeSubitemState(index, _index, subitem.id, e.target.value)}} aria-label="gender" name={`subitem-${subitem.id}`} >
                                                      <FormControlLabel style={{marginLeft: '-17px'}} value={'0'} control={<RadioNo/>}/>
                                                      <FormControlLabel style={{marginLeft: '-17px'}} value={'-1'} control={<RadioNeutro />}  />
                                                      <FormControlLabel style={{marginLeft: '-17px'}} value={'1'} control={<RadioYes />}  />
                                                    </RadioGroup>
                                                  </FormControl>
                                                </ListItemSecondaryAction>
                                              </ListItem>
                                            ))
                                          }
                                        </div>
                                      ))
                                    }
                                    </ul>
                                  </li>
                              </List>
                              <Button
                                variant="contained"
                                color="secondary"
                                className="full_content"
                                onClick={(e) => {this._fetchSaveRequest()}}
                              >
                                Guardar cambios
                              </Button>
                            </section>
                        </ThemeProvider>
                        <VerLogs ruta={__Uri.path+"/usuarios/logs"}/>
                        <BtnAdd/>
                    </form>
                </div>
            </div>
        )
    }
}
export default withRouter(AdminAsignacionPerm);
