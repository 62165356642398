import React from 'react';
import '../../assets/css/general.css';

export default function Subtitulo(props) {
    const uppercase = props.uppercase
    const styleUpper = {marginTop: '14px', marginBottom: '14px', textTransform: 'uppercase'}  
    const styleNormal = {marginTop: '14px', marginBottom: '14px'}   
    return (
      <h3 style={uppercase? styleUpper : styleNormal} className="fnt_regular fnt_14 inline">{props.text}</h3>
    );
}
