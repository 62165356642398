import React, { Component } from "react";
import {COLORS,__Uri} from './components/general/Constants';
import logo from './logo.svg';
import './App.css';
import './assets/css/general.css';
import { BrowserRouter } from 'react-router-dom';
import { Switch, Route, Link, Redirect} from "react-router-dom";
import Header from './components/layout/Header'
import Footer from './components/layout/Footer'
import Home from './views/Home'
import Configuracion from './views/Configuracion'
import Login from './views/login/Login'
import Error from './views/Error'
import Usuariodata from './views/sisusuario/CrearData'
import ListadoGestion from './views/sisusuario/ListadoGestion'
import PerfilUsuario from './views/sisusuario/PerfilUsuario'
import AdminAsignacion from './views/sisusuario/AdminAsignacion'
import AdminAsignacionPerm from './views/sisusuario/AdminAsignacionPerm'
import RegistroLogs from './views/sisusuario/RegistroLogs'

import { createMuiTheme } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles';
import MenuSide from './components/general/MenuSide';
import { dark } from "@material-ui/core/styles/createPalette";
import ApiToken from './util/ApiToken';

const theme = createMuiTheme({
  // palette: {
  //   primary: {
  //     main: '#9f8e6e',
  //     contrastText: '#fff',
  //   },
  //   secondary: {
  //     main: COLORS.text_gris,
  //     contrastText: COLORS.bg_footer,
  //   },
  // },
  typography: {
    // fontFamily: 'Raleway, Arial',
    fontFamily: [
      'Montserrat',
      'sans-serif',
    ].join(','),
  },
  palette: {
    type:'dark',
    primary: {
      light: ' #1E232B',
      main: '#3d4755',
      dark: '#13161d',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ffffff',
      main: '#788ba5',
      dark: '#546e91',
      contrastText: '#fff',
    },
    tertiary: {
      light: '#788ba5',
      main: '#ffffff',
      dark: '#546e91',
      contrastText: '#5b6a7f',
    },
    background:{
      paper: '#1e232b',
    },
    text:{
      primary: '#fff',
      secondary: '#5b6a7f',
      disabled: '#1E232B',
      hint: '#788ba5'
    },
  },
});
class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
        user : null,
        login : false,
        view : false,
    }
  }
  componentWillMount = async () => {
    let _self = this
    await ApiToken.request('post','verify-init',{}, function(res){
      if(res.response){
        _self.setState({ user : res.user })
        _self.setState({ login : true })
      }
      _self.setState({ view : true })
    })
  }
  _fetchRequestLogin = (_user)=>{
    this.setState({ user : _user })
    this.setState({ login : true })
  }
  _fetchRequestLogout = () =>{
    this.setState({ login : false })
    this.setState({ user : null })
  }
  _fetchVerifyAccess = (_id) => {
    let access = false
    this.state.user.access.forEach((item, i) => {
      if(_id === item.id) access = (access || item.access)
    });
    return access
  }
  render(){
    const menus = {
      // slide: {buttonText: 'Slide', items: 2},
      // stack: {buttonText: 'Stack', items: 1},
      // elastic: {buttonText: 'Elastic', items: 1},
      // bubble: {buttonText: 'Bubble', items: 2},
      // push: {buttonText: 'Push', items: 1},
      // pushRotate: {buttonText: 'Push Rotate', items: 2},
      scaleDown: {buttonText: 'Scale Down', items: 1},
      scaleRotate: {buttonText: 'Scale Rotate', items: 1},
      // fallDown: {buttonText: 'Fall Down', items: 2},
      // reveal: {buttonText: 'Reveal', items: 1}
    };
    return this.state.view && (
      <ThemeProvider theme={theme}>
        <BrowserRouter>
        {this.state.login && <Header onSetLogout={this._fetchRequestLogout}/>}
        {
          this.state.login ?
          (
            <MenuSide menus={menus} style={{height: '100vh'}} viewSideBar={this.state.user.root}>
              {/*<Navigation />*/}
              <Switch>
                <Route path={__Uri.path+"/"} exact>
                  <Redirect to={__Uri.path+"/home"}/>
                </Route>
                <Route path={__Uri.path+"/login"}>
                  <Redirect to={__Uri.path+"/home"}/>
                </Route>
                <Route path={__Uri.path+"/home"}>
                  <Home hasAccess={this._fetchVerifyAccess}/>
                </Route>
                <Route path={__Uri.path+"/sistem-usuarios/gestion"}>
                  <Configuracion hasAccess={this._fetchVerifyAccess}/>
                </Route>
                <Route path={__Uri.path+"/sistem-usuarios/usuario/nuevo"} component={PerfilUsuario}/>
                <Route path={__Uri.path+"/sistem-usuarios/usuario/:id/editar"} component={PerfilUsuario}/>
                <Route path={__Uri.path+"/sistem-usuarios/rol/nuevo"}>
                  <Usuariodata index={1}/>
                </Route>
                <Route path={__Uri.path+"/sistem-usuarios/grupo-rol/nuevo"}>
                  <Usuariodata index={2}/>
                </Route>
                <Route path={__Uri.path+"/sistem-usuarios/grupo-usuario/nuevo"}>
                  <Usuariodata index={3}/>
                </Route>
                <Route path={__Uri.path+"/sistem-usuarios/rol/:id/editar"}>
                  <Usuariodata index={1}/>
                </Route>
                <Route path={__Uri.path+"/sistem-usuarios/grupo-rol/:id/editar"}>
                  <Usuariodata index={2}/>
                </Route>
                <Route path={__Uri.path+"/sistem-usuarios/grupo-usuario/:id/editar"}>
                  <Usuariodata index={3}/>
                </Route>
                <Route path={__Uri.path+"/sistema/:id/asignacion"}>
                  <AdminAsignacion  hasAccess={this._fetchVerifyAccess}/>
                </Route>
                <Route path={__Uri.path+"/sistem-usuarios/asignacion/:id/:path/:item/permisos"} component={AdminAsignacionPerm}/>
                <Route path={__Uri.path+"/usuarios/logs"} component={RegistroLogs}/>
                <Route component={Error}/>
              </Switch>
              <Footer/>
            </MenuSide>
          ):(
            <div>
              <Switch>
                <Route path={__Uri.path+"/login"}>
                  <Login onSetLogin={this._fetchRequestLogin}/>
                </Route>
                <Route>
                  <Redirect to={__Uri.path+"/login"}/>
                </Route>
              </Switch>
              <Footer/>
            </div>
          )
        }
        </BrowserRouter>
      </ThemeProvider>
    );
  }
}

export default App;
