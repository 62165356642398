import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import CardItem from '../components/general/CardItem';
import Button from '@material-ui/core/Button';
import ApiToken from '../util/ApiToken';
import imgDefault from '../assets/img/obelisco.jpg'
const __accesID = {
  view : 2,
  config : 3,
};
class Home extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
          lista : [],
          view : false,
          access : false,
          config : false,
      }
    }
    componentWillMount = () => {
      let active = this.props.hasAccess(__accesID.view)
      console.log(active)
      this.setState({config : this.props.hasAccess(__accesID.config)})
      let _self = this
      if(active){
        ApiToken.request('put','sistema/get',{}, function(res){
          if(res.response){
            _self.setState({ lista : res.list })
            _self.setState({ access : active })
            _self.setState({ view : true })
          }
        })
      }else{
        _self.setState({ view : true })
      }
    }
    render (){
      return this.state.view && (
        <div style={{ height: "100vh", padding: "0 10px" }}>
          <div className="contenido">
            {
              (this.state.access) ?
              (
                <Grid style={{ flexGrow: 1 }} xs={12}>
                    <Grid container justify="flex-start" alignItems="baseline" spacing={2}>
                        {
                            this.state.lista.map(item => (
                                <Grid item xs={6} md={3}>
                                    <CardItem
                                      title={item.nombre}
                                      urlimage={imgDefault}
                                      urlLink={item.access ? item.dominio : 'javascript:void(0)'}
                                      acceso={item.access} btnHome
                                      admin = {this.state.config ? true: false}
                                      keyID = {item.key}
                                      key = {item.id}
                                    />
                                </Grid>
                            ))
                        }
                    </Grid>
                </Grid>
              ):(
                <div style={{textAlign: 'center', lineHeight:'20px'}}>
                  <h3  className="fnt_18">Bienvenido al sistema central de Obelisco</h3>
                  <p className="fnt_14">Para poder continuar requiere de permisos, contactese con el encargado</p>
                </div>
              )
            }
          </div>
        </div>
      );
    }
}

export default Home;
