import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { COLORS, __Uri} from './Constants';
import Card from '@material-ui/core/Card';
import Link from '@material-ui/core/Link';
import CardContent from '@material-ui/core/CardContent';

import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';

import PersonIcon from '@material-ui/icons/Person';

import person from '../../assets/img/person.jpg'
import ViewStreamIcon from '@material-ui/icons/ViewStream';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { useHistory } from "react-router-dom";


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    background: COLORS.bg_card,
    margin: '10px 0',
    maxWidth: '100%',
    backgroundColor: COLORS.bg_dark,
    border: 'solid 1px '+COLORS.contrast_white,
    borderRadius: 10,
    fontSize: 12,
    marginBottom: 15,
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  content: {
    flex: '1 0 auto',
    color: COLORS.text_white
  },
  cover: {
    width: 151,
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  playIcon: {
    color: 'green',
    // height: 38,
    // width: 38,
  },
  image: {
    width: 128,
    height: 128,
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
  },
  header:{
    fontSize: 14,
  },
  subheader:{
    fontSize: 12,
  },
  butonaction:{
    padding: '26px 0',
  },
}));

export default function MediaControlCard2(props) {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory()
  const goToPerfil = (e) => {
    e.preventDefault()
    history.push(props.rutaedit)
  }

  return (

        <Card className={classes.root} >
            {/* <CardMedia
                className={classes.cover}
                image="https://material-ui.com/static/images/grid/complex.jpg"
                title="rol"
            /> */}
            <div className={classes.details}>
              <Link component="a" href={__Uri.path+props.rutaedit}>
                <CardContent className={classes.content}>
                  <div className={classes.subheader}>
                    <Grid container spacing={1} alignItems="center">
                      <Grid item xs={2} justify="center" alignItems="center">
                        <Avatar alt="Remy Sharp" src={(props.avatar)? props.avatar: '__Uri.path+person'} >{(props.type)? props.type: ''}</Avatar>
                      </Grid>
                      <Grid item xs={8}>
                      <div className={classes.header}>
                        <PersonIcon fontSize='small' className="alignv_mid"/><span className="alignv_sub">{props.titulo}</span>
                      </div>
                        {props.subtitulo != null && (<p><ViewStreamIcon fontSize='small' className="alignv_sub"/><span>{props.subtitulo}</span></p>)}
                      </Grid>
                    </Grid>
                  </div>
                </CardContent>
              </Link>
              {/* <div className={classes.controls}>

              </div> */}
            </div>
            <div  className={classes.butonaction} alignItems="center">
              <ButtonGroup
                orientation="horizontal"
                color="primary"
                size="small"
                aria-label="vertical contained primary button group"
                variant="text"
                style={{indexZ:1000}}
              >
                {/* <Button href={__Uri.path+props.rutaedit}><EditIcon/></Button> */}
                <Button onClick={(e)=>{props.actionDelete()}}><DeleteIcon/></Button>
              </ButtonGroup>
            </div>
        </Card>

  );
}
