import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import RestoreIcon from '@material-ui/icons/Restore';
import FavoriteIcon from '@material-ui/icons/Favorite';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { COLORS, __Uri} from '../general/Constants';
import { createMuiTheme } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles'

import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import ListIcon from '@material-ui/icons/List';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import MailOutlineIcon from '@material-ui/icons/MailOutline';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles({
  root: {
    width: 'max-width',
    position: 'fixed',
    right: 0,
    left: 0,
    bottom: 0,
    zIndex:10000
  },
});
const innerTheme = createMuiTheme({
  palette: {
    primary:{
      main: '#fff',
      contrastText: '#9f8e6e',
      dark: '#fff',
      light:"rgba(159, 142, 110, 1)",
      dark:"#fff",
      hint:"rgba(0, 0, 0, 0.38)",
    },
    secondary: {
      main: "#9f8e6e",
    },
    background:{
      paper:'#bdad90',
      default:'#fff'
    },
  },
});
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" style={{
    zIndex:2,
    margin:'-45px 0',
    alignItems:'end'
  }} ref={ref} {...props} />;
});
export default function SimpleBottomNavigation() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const btn_nav = {
    minWidth: '60px', 
    color: COLORS.text_white
  }

  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  var stylebtnsig={
      textTransform:'capitalize', 
      borderRadius:0,
      margin:'10px 0'
  }
  return (
    <div>
      <ThemeProvider theme={innerTheme}>
        <BottomNavigation colored="true" color="secondary"
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
          showLabels
          className={classes.root}
        >
          <BottomNavigationAction style={btn_nav} icon={<NavigateBeforeIcon />} />
          <BottomNavigationAction style={btn_nav} icon={<NavigateNextIcon />} />
          <BottomNavigationAction style={btn_nav} icon={<ListIcon />} />
          <BottomNavigationAction style={btn_nav} icon={<HomeOutlinedIcon />} />
          <BottomNavigationAction style={btn_nav} icon={<SearchOutlinedIcon />} />
          <BottomNavigationAction style={btn_nav} icon={<MailOutlineIcon />}
          onClick={handleClickOpen} />
        </BottomNavigation>
      </ThemeProvider>
      <Dialog 
        id="dialog_preg"      
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        style={{margin:'0px !important'}}     
        fullWidth
        maxWidth ='false'
      >
        <div id="alert-dialog-slide-title" className="fnt_18 fnt_medium" style={{background:COLORS.bg_cafe, color:COLORS.text_white, fontFamily:'Raleway', textAlign:'center', fontWeight:'400', padding: '15px 0 10px 0'}}>
          <div className="line_dudas"></div>
          DUDAS Y PREGUNTAS
          </div>
        <DialogContent style={{background:COLORS.bg_white}}>
          <DialogContentText id="alert-dialog-slide-description">
            <section>
                <p className="textdudas fnt_semibold fnt_14">No te cortes!<br/>Escribe tus dudas y preguntas sobre el Curso.</p>
                <form>
                    <TextField
                        id="outlined-multiline-dudas"
                        // label="Multiline"
                        multiline
                        fullWidth
                        rows={5}
                        // defaultValue="Default Value"
                        variant="outlined"
                        placeholder="Escribe cualquier duda o pregunta sobre el temario"
                    />
                    <Button variant="outlined" color="primary" fullWidth style={stylebtnsig} onClick={handleClose} >
                        ENVIAR
                    </Button>
                </form>
            </section>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}