import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import '../../assets/css/componentes.css'
 
export default function FormLoginDorado(props){
	const useStyles = makeStyles((theme) => ({
		root: {
      		width: '95px',
    	},
		
	}));
	const classes = useStyles();
	return (
		<div className="logo_login">
        	<img src={props.logo}  alt={props.alt} className={classes.root}/>
    	</div>
	);
}
