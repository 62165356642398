import React, { Component } from "react";
export const __Uri = {
  server : (document.getElementById('root').dataset.hasOwnProperty('server')) ? document.getElementById('root').dataset.server : '',
  home : (document.getElementById('root').dataset.hasOwnProperty('home')) ? document.getElementById('root').dataset.home : '',
  path : (document.getElementById('root').dataset.hasOwnProperty('path')) ? document.getElementById('root').dataset.path : ''
}

export const COLORS = {
    text_white: '#ffffff',
    bg_white: '#ffffff',    
    bg_card: '#494D62',
    bg_footer: '#bdad90',
    text_gold: '#BDAD90',
    text_gold2: '#9f8e6e',
    text_gris: '#999999',
    text_gris2: '#777777',
    icon_gold: '#BDAD90',
    icon_gold2: '#9f8e6e',
    icon_disable: '#dddddd',
    color_border: '#bbbbbb',
    bg_cafe: '#BDAD90',
    bg_tab: 'rgba(159,142,110,0.1)',
    text_contenido: '#777777',
    text_danger: '#AA1111',
    text_success: '#53AB45',
    bg_paper: '#1E232B',
    bg_dark: '#13161d',
    contrast_white: '#3d4755',
  };
export const COLORS_white = {
    text_white: '#ffffff',
    bg_white: '#ffffff',
    bg_card: '#494d62',
    bg_footer: '#bdad90',
    text_gold: '#7f6b4d',
    text_gold2: '#9f8e6e',
    text_gris: '#999999',
    text_gris2: '#777777',
    icon_gold: '#BDAD90',
    icon_gold2: '#9f8e6e',
    icon_disable: '#dddddd',
    color_border: '#bbbbbb',
    bg_cafe: '#BDAD90',
    bg_tab: 'rgba(159,142,110,0.1)',
    text_contenido: '#777777',
    text_danger: '#AA1111',
    text_success: '#53AB45'
  };
const SIZES = [
    8,
    16,
    24,
    32,
];

