import React from 'react';
import { COLORS, __Uri} from '../../components/general/Constants';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import Titulo from '../../components/general/Titulo'
import BtnAdd from '../../components/forms/BtnAdd'
import ListItem from '../../components/general/ListItem'
import ListItem2 from '../../components/general/ListItem2'
import ApiToken from '../../util/ApiToken';
import Swal from 'sweetalert2'

const filter = createFilterOptions();
const url = [
  'usuario',
  'rol',
  'grupo-rol',
  'grupo-usuario',
];
class ListaGestion extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
          valor: null,
          view: false,
          lista: [],
          original: [],
      };
    }
    componentWillMount = () => {
      console.log("Hola")
        this._fetchGetList()
    }
    _fetchGetList = () => {
      let _self = this
      ApiToken.request('put',`${url[this.props.index]}/get`,{}, function(res){
        if(res.response){
          _self.setState({ lista : [...res.list] })
          _self.setState({ original : [...res.list] })
          _self.setState({ view : true })
        }
      })
    }
    _fetchFilter = (val) => {
      this.setState({lista : this.state.original.filter(item => {
        if(this.props.index == 0)
         return ([item.nombre,item.primer_apellido,item.segundo_apellido].join(' ')).toLowerCase().includes(val.toLowerCase())
        return (item.titulo.toLowerCase().includes(val.toLowerCase()))
      })})
    }
    _fetchDeleteItem = (keyID) => {
      let _Self = this
      Swal.fire({
        text: `¿Desea eliminar el este item?`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: `Aceptar`,
        cancelButtonText: `Cancelar`,
        customClass:{
            popup:'bg_dark',
            confirmButton: 'btn_success',
            denyButton:'btn_deny',
            cancelButton:'btn_cancel',
            htmlContainer:'text_white',
            title:'text_white',
        }
      }).then((result) => {
          if (result.isConfirmed) {
            ApiToken.request('post',`${url[_Self.props.index]}/delete`, {
              keyID : keyID,
            }, function(res){
              if(res.response){
                _Self._fetchGetList()
                Swal.fire( '', res.message, 'success' )
              }else{
                Swal.fire( '¡Error!', res.message, 'error' )
              }
            })
          }
      })
    }
    render(){
        const darkTheme = createMuiTheme({
          palette: {
            type: 'dark',
            secondary: {
              light: '#ffffff',
              main: '#788ba5',
              dark: '#546e91',
              contrastText: '#fff',
            }
          },
          secondary: {
              light: '#ffffff',
              main: '#788ba5',
              dark: '#546e91',
              contrastText: '#fff',
            }
        });
        const lista= {
          flexGrow: 1,
          overflow: 'hidden',
          padding: '10px 0',
        };
        return this.state.view && (
            <div style={{ padding:'0 10px'}}>
                <div className="contenido max_360">
                    <Titulo text={this.props.title}/>
                    <form>
                        <ThemeProvider theme={darkTheme}>
                          <TextField
                              variant="outlined"
                              label="Buscar"
                              className="input_dark"
                              color="secondary"
                              onChange={(e)=>{this._fetchFilter(e.target.value)}}
                          />
                          {this.state.lista.map(item => (
                            this.props.index == 0 ?
                            (<ListItem2
                              //avatar={ruta}
                              nombre={`${[item.nombre,item.primer_apellido,item.segundo_apellido].join(' ')}`}
                              email={item.email}
                              telefono={item.celular == null ? item.telefono : item.celular}
                              rutaedit={`./${url[this.props.index]}/${item.keyID}/editar`}
                            />):(<ListItem
                              type={this.props.tipo}
                              titulo={item.titulo}
                              subtitulo={item.hasOwnProperty('subtitulo') ? item.subtitulo : null}
                              key={item.id}
                              rutaedit={`./${url[this.props.index]}/${item.keyID}/editar`}
                              actionDelete={(e)=>{this._fetchDeleteItem(item.keyID)}}
                            />)
                          ))}
                          <BtnAdd/>
                        </ThemeProvider>
                    </form>
                </div>
            </div>
        )
    }
}
export default ListaGestion;
