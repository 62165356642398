import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';



export default function FormDialog() {
  const theme = React.useMemo(
    () =>
      createMuiTheme({
        palette: {
          type: 'dark',
            primary: {
            main: '#fff',
            dark: '#fff',
            Textcontrast: '#fff',
          },
        },
      }),
  );
  const [open, setOpen] = React.useState(false);

  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <ThemeProvider theme={theme}>
    <div>
      <a href="javascript:" onClick={handleClickOpen} className="texto_login link_a">
        ¿Has olvidado tu contraseña?
      </a>
      <Dialog 
        open={open} 
        onClose={handleClose} 
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title" className="titulo_recuperar">¿Olvidaste tu contraseña?</DialogTitle>
        <DialogContent>
          <DialogContentText align="center" className="contenido_popup">
            Introduce tu dirección de correo electrónico y te enviaremos las instrucciones para restablecerla.
          </DialogContentText>
          <TextField id="outlined-basic" label="Tu correo" variant="outlined" type="email" size="normal" className="border_input" fullWidth/>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} className="boton_cancelar" variant="outlined">
            Cancelar
          </Button>
          <Button onClick={handleClose} className="boton_enviar" variant="contained">
            Enviar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
    </ThemeProvider>
  );
}

