import React from 'react';
import Grid from '@material-ui/core/Grid';
import { COLORS } from '../general/Constants';
import Button from '@material-ui/core/Button';
import LockIcon from '@material-ui/icons/Lock';
import { ThemeProvider } from '@material-ui/styles';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import MailOutline from '@material-ui/icons/MailOutline';
import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#fff',
      dark: '#fff',
      Textcontrast: '#fff',
    },
    secondary: {
      main: '#9F8E6E',
      light: '#ffffff',
      Textcontrast: '#fff',
    },
    text:{
		primary:'#fff',
		secondary:'#fff',
		disabled:'#fff',
		hint:'#fff'
	}
  },
});

const useStyles = makeStyles((theme) => ({
	root: {
  		margin: theme.spacing(1),
  		width: '260px',
	},
	margin: {
		margin: '20px 0',
		},
}));

export default function FormLoginDorado(props){
	
	const classes = useStyles();

	const stylebg_icon = {
        background: COLORS.bg_icon_lg,
    };

	var icon_style= {margin: '16px 0'}
	var text_color = {color: '#fff'}

		return (
		<ThemeProvider theme={theme}>
			<div className="form_login">
				<form className={classes.root} noValidate autoComplete="off">
		  				<div className={classes.margin}>
		        			<Grid container spacing={0} alignItems="center">
							    <Grid item style={stylebg_icon} className="border_input cont_icon">
			            			<MailOutline style={icon_style}/>
			          			</Grid>
			          			<Grid item style={{width: 'calc(100% - 50px)'}}>
			            			<TextField id="outlined-basic" label="Usuario" variant="outlined" size="normal" className="border_input"/>
			          			</Grid>
		        			</Grid>
		      			</div>

		      			<div className={classes.margin}>
		        			<Grid container spacing={0} alignItems="center">
							    <Grid item style={stylebg_icon} justify="center" className="border_input cont_icon">
			            			<LockIcon style={icon_style} />
			          			</Grid>
			          			<Grid item style={{width: 'calc(100% - 50px)'}}>
			            			<TextField id="outlined-basic" color="primary" label="Contraseña" type="password" variant="outlined" size="normal" className="border_input"/>
			          			</Grid>
		        			</Grid>
		      			</div>

		      			<div>
		      				<Button className="botom_login" style={text_color} variant="contained" color="secondary">
		        				ENTRAR
		      				</Button>
		      			</div>
	      			
				</form>
			</div>
		</ThemeProvider>
	);
}

