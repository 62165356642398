import React from 'react';
import { COLORS, __Uri } from '../general/Constants';
import { makeStyles } from '@material-ui/core/styles';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Backdrop from '@material-ui/core/Backdrop';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import StorefrontIcon from '@material-ui/icons/Storefront';
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    height: 380,
    transform: 'translateZ(0px)',
    flexGrow: 1,
  },
  speedDial: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));
const actions = [
    { icon: <PersonAddIcon />, name: 'Usuario', link:'/sistem-usuarios/usuario/nuevo', id_access: 12},
    { icon: <SupervisorAccountIcon />, name: 'Rol', link:'/sistem-usuarios/rol/nuevo', id_access:  4},
    { icon: <GroupAddIcon />, name: 'Grupo Usuario', link:'/sistem-usuarios/grupo-usuario/nuevo', id_access: 6},
    { icon: <GroupWorkIcon />, name: 'Grupo Rol', link:'/sistem-usuarios/grupo-rol/nuevo', id_access: 8},
    //{ icon: <StorefrontIcon />, name: 'Sucursal', link:'/home', id_access: 10 },
];

export default function BtnAdd(props) {
  const classes = useStyles();
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const [hidden, setHidden] = React.useState(false);
  const _actions = (props.hasOwnProperty('hasAccess'))
                    ? actions.filter(item => (props.hasAccess(item.id_access)))
                    : actions
  const handleVisibility = () => {
    setHidden((prevHidden) => !prevHidden);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const darkTheme = createMuiTheme({
    palette: {
      type: 'dark',
      secondary: {
        light: '#546e91',
        main: '#788ba5',
        dark: '#546e91',
        contrastText: '#fff',
      }
    },
    secondary: {
        light: '#ffffff',
        main: '#788ba5',
        dark: '#546e91',
        contrastText: '#fff',
      }
  });
  const lista= {
    flexGrow: 1,
    overflow: 'hidden',
    padding: '10px 0',
  };

  return (
    <div>
      {/* <Button onClick={handleVisibility}>Toggle Speed Dial</Button>
      <Backdrop open={open} /> */}
      <ThemeProvider theme={darkTheme}>
      <SpeedDial
        ariaLabel="Crear nuevo"
        className={classes.speedDial}
        hidden={hidden}
        icon={<SpeedDialIcon/>}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
        // color="secondary"
      >
        {_actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            tooltipOpen
            onClick={(e)=>{
              e.preventDefault()
              history.push(action.link)
            }}
            className="optionspeed"
          />
        ))}
      </SpeedDial>
      </ThemeProvider>
    </div>
  );
}
