import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PhoneIcon from '@material-ui/icons/Phone';
import FavoriteIcon from '@material-ui/icons/Favorite';
import PersonPinIcon from '@material-ui/icons/PersonPin';
import HelpIcon from '@material-ui/icons/Help';
import ShoppingBasket from '@material-ui/icons/ShoppingBasket';
import ThumbDown from '@material-ui/icons/ThumbDown';
import ThumbUp from '@material-ui/icons/ThumbUp';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import BtnAdd from '../components/forms/BtnAdd'

import ListadoGestion from '../views/sisusuario/ListadoGestion'
import Navigation from '../components/general/Navigation'
import { useHistory } from "react-router-dom";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  fab: {
    margin: theme.spacing(2),
  },
  fixed: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(3),
  },
}));
// 12 	1 	GESTIÓN DE USUARIOS
// 10 	1 	GESTIÓN DE SUCURSALES
// 4 	1 	GESTIÓN DE ROLES
// 6 	1 	GESTIÓN DE GRUPOS DE USUARIOS
// 8 	1 	GESTIÓN DE GRUPOS DE ROLES
const access = {
  usuario : 12,
  rol : 4,
  gruporol : 8,
  grupousuario : 6,
  sucursal : 10,
}
const nav = {
  '#roles': 1,
  '#usuarios': 0,
  '#grupo-usuario': 3,
  '#grupo-rol': 2,
}
export default function Configuracion(props) {
  const history = useHistory()
  const [value, setValue] = React.useState((nav.hasOwnProperty(`${history.location.hash}`) ? nav[history.location.hash] : 0));
  const classes = useStyles();
  const lista_gestion = [
    {
      index : 0,
      access : 12,
      title : 'Gestión de Usuarios',
      tipo : 'U'
    },
    {
      index : 1,
      access : 4,
      title : 'Gestión de Roles',
      tipo : 'R'
    },
    {
      index : 2,
      access :8,
      title : 'Gestión de Grupo Roles',
      tipo : 'GR'
    },
    {
      index : 3,
      access : 6,
      title : 'Gestión de Grupo Usuarios',
      tipo : 'GU'
    }
  ]
  React.useEffect(() => {
      let temp = [];
      lista_gestion.forEach((item, i) => {
        if (props.hasAccess(item.access)) temp.push(item.index)
      });
      if(temp.length>0){
        let x = temp.reverse().pop()
        setValue(x)
      }
    }, [])
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div style={{height: '100vh', padding:'0 10px'}}>
      <div className="contenido">
        <div className={classes.root}>
          <AppBar position="static" color="tertiary">
            <Tabs
              value={value}
              variant="scrollable"
              scrollButtons="on"
              indicatorColor="primary"
              textColor="primary"
              aria-label="scrollable force tabs example"
            >
            {
              lista_gestion.map(item => {
                return props.hasAccess(item.access) ? (<Tab label={item.title} {...a11yProps(item.index)} onClick={(e)=>{handleChange(e,item.index)}}/>) : ('')
              })
            }
            </Tabs>
          </AppBar>
          {
            lista_gestion.map(item => {
              return props.hasAccess(item.access) ? (
                <TabPanel value={value} index={item.index}>
                  <ListadoGestion tipo={item.tipo} index={item.index} title={item.title}/>
                </TabPanel>
              ) : ('')
            })
          }
          <BtnAdd hasAccess={props.hasAccess}/>
        </div>
      </div>
      {/* <Navigation/> */}
    </div>

  );
}
