import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { COLORS, __Uri} from './Constants';
import Card from '@material-ui/core/Card';
import Link from '@material-ui/core/Link';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import ButtonBase from '@material-ui/core/ButtonBase';

import PersonIcon from '@material-ui/icons/Person';
import PhoneIcon from '@material-ui/icons/Phone';

import person from '../../assets/img/person.jpg'
import EmailIcon from '@material-ui/icons/Email';
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    background: COLORS.bg_card,
    margin: '10px 0',
    maxWidth: '100%',
    backgroundColor: COLORS.bg_dark,
    border: 'solid 1px '+COLORS.contrast_white,
    borderRadius: 10,
    fontSize: 12,
    marginBottom: 15,
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  content: {
    flex: '1 0 auto',
  },
  cover: {
    width: 151,
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  playIcon: {
    color: 'green',
    // height: 38,
    // width: 38,
  },
  image: {
    width: 128,
    height: 128,
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
  },
  header:{
    fontSize: 14,
  },
  subheader:{
    fontSize: 12,
  },
}));

export default function MediaControlCard(props) {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory()
  const goToPerfil = (e) => {
    e.preventDefault()
    history.push(props.rutaedit)
  }
  return (
    <Link component="a" onClick={(e) => {goToPerfil(e)}}>
      <Card className={classes.root} >
        {/* <CardMedia
            className={classes.cover}
            image="https://material-ui.com/static/images/grid/complex.jpg"
            title="rol"
        /> */}
        <Grid container justify="center"alignItems="center" xs="3">
          <Avatar alt="Remy Sharp" src={(props.avatar)? props.avatar: __Uri.path+person} />
        </Grid>
        <div className={classes.details}>
          <CardContent className={classes.content}>
          <div className={classes.header}>
            <PersonIcon fontSize='small' className="alignv_sub"/><span>{props.nombre}</span>
            </div>
          <div className={classes.subheader}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <p><EmailIcon fontSize='small' className="alignv_sub"/><span>{props.email}</span></p>
              </Grid>
              <Grid item xs={12}>
                <p><PhoneIcon fontSize='small' className="alignv_sub"/><span>{props.telefono}</span></p>
              </Grid>
            </Grid>
          </div>
          </CardContent>
          {/* <div className={classes.controls}>

          </div> */}
        </div>
      </Card>
    </Link>
  );
}
