import React from 'react';
import { COLORS, __Uri } from './Constants';
import { createMuiTheme } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles'
import { makeStyles } from '@material-ui/core/styles';
import '../../assets/css/general.css'
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
// import Progress from '../general/ProgressBar';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import SettingsIcon from '@material-ui/icons/Settings';
import Typography from '@material-ui/core/Typography';
import LabelOutlinedIcon from '@material-ui/icons/LabelOutlined';
import { useHistory } from "react-router-dom";

var styletag = {
  color: COLORS.text_gris,
  textTransform: 'uppercase',
}
var styleTitle = {
  color: COLORS.text_gold,
  fontFamily: 'Raleway',
  margin: '0',
}
var bgbtncardH={
  background: COLORS.bg_cafe,
  color: COLORS.text_white,
  padding: '2px 8px'
}
var btncardH={
  background: COLORS.bg_cafe,
  color: COLORS.text_white,
  padding: '0px 5px'
}

var bgbtncardC={
  background: COLORS.bg_white,
  textAlign: 'right',
  display: 'block'
}
var btncardC={
  background: COLORS.bg_white,
  color: COLORS.text_gold,
}
var btncardCDisabled={
  background: COLORS.bg_white,
  color: COLORS.text_gris,
}

const theme2 = createMuiTheme({
  palette: {
    primary: {
      main: COLORS.bg_card,
      contrastText:COLORS.bg_card,
    },
    secondary: {
      main: COLORS.text_gris,
      contrastText: COLORS.bg_footer,
    },
    background:{
      paper:COLORS.bg_card,
      default:'#fff'
    },
  },
});
export default function ImgMediaCard(props) {
  const history = useHistory()
  const goToAsignacion = (e) => {
    e.preventDefault()
    history.push(`/sistema/${props.keyID}/asignacion`)
  }
  return (
    <ThemeProvider theme={theme2}>
      <Card primary className={props.btnHome ? 'item_card' : 'item_cardfull'}>
        <CardActionArea href={props.urlLink}>
        {props.admin ==  true ?
            <Button
              size="small"
              color="primary"
              style={{background: COLORS.bg_card,color: COLORS.text_gold, position: 'absolute', right:0, top:0, zIndex:2, minWidth: '40px'}}
              className="fnt_12 fnt_bold"
              onClick = {(e) => {goToAsignacion(e)}}
            >
                <SettingsIcon></SettingsIcon>
            </Button>: ''}
          <CardMedia
            component="img"
            alt={props.title}
            height="140"
            image={props.urlimage}
            title={props.title}
          />

          <CardContent style={{padding:'10px 10px 10px 10px'}}>
            <h2 style={styleTitle} className="title_card fnt_16 fnt_semibold">
              {props.title}
            </h2>
            <Typography variant="body2" color="textSecondary" component="p">
              {props.description}
            </Typography>
            {/* <Progress valor={props.progress} /> */}
          </CardContent>
        </CardActionArea>
        <CardActions style={props.btnHome ? bgbtncardH : bgbtncardC}>
          <Button
          size="small"
          color="primary"
          endIcon={props.acceso ==  true ? <ArrowForwardIcon></ArrowForwardIcon>: <LockOutlinedIcon></LockOutlinedIcon>}
          style={props.btnHome ? btncardH : (props.acceso ? btncardC : btncardCDisabled)}
          className="fnt_12 fnt_bold"
          href={props.urlLink}
          >
            {props.acceso ==  true ? 'ENTRAR' : 'SIN ACCESO'}
          </Button>
        </CardActions>
      </Card>

    </ThemeProvider>
  );
}
