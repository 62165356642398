import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import BurgerMenu from 'react-burger-menu';
import classNames from 'classnames';
import '../../assets/css/menuside/menuburguer.css'
import '../../assets/css/menuside/normalize.css'
import LinkMenu from '../layout/LinkMenu'

class MenuWrap extends Component {
  constructor (props) {
    super(props);
    this.state = {
      hidden: false
    };
  }

  componentWillReceiveProps(nextProps) {
    const sideChanged = this.props.children.props.right !== nextProps.children.props.right;

    if (sideChanged) {
      this.setState({hidden : true});

      setTimeout(() => {
        this.show();
      }, this.props.wait);
    }
  }

  show() {
    this.setState({hidden : false});
  }

  render() {
    let style;

    if (this.state.hidden) {
      style = {display: 'none'};
    }

    return (
      <div style={style} className={this.props.side}>
        {this.props.children}
      </div>
    );
  }
}

class MenuSide extends Component {
  constructor (props) {
    super(props);
    this.state = {
      currentMenu: 'scaleRotate',
      side: 'left'
    };
  }

  // changeMenu(menu) {
  //   this.setState({currentMenu: menu});
  // }

  // changeSide(side) {
  //   this.setState({side});
  // }

  getItems() {
    let items;

    switch (this.props.menus[this.state.currentMenu].items) {
      case 1:
        items = [
          <h2 key="0"><i className="fa fa-fw fa-inbox fa-2x" /><span>Obelisco</span></h2>,
          <a key="0" href="/"><i className="fa fa-fw fa-star-o" /><span>Inicio</span></a>,
          <a key="1" href="/perfil"><i className="fa fa-fw fa-bell-o" /><span>Perfil</span></a>,
          // <a key="2" href="/sorteo"><i className="fa fa-fw fa-envelope-o" /><span>Sorteo</span></a>,
          // <a key="3" href="/ganador-adm"><i className="fa fa-fw fa-envelope-o" /><span>Ganador ADM</span></a>,
          // <a key="4" href="/listados"><i className="fa fa-fw fa-envelope-o" /><span>Listados</span></a>,
        ];
        break;
      case 2:
        items = [
          <h2 key="0"><i className="fa fa-fw fa-inbox fa-2x" /><span>Sidebar</span></h2>,
          <a key="1" href=""><i className="fa fa-fw fa-database" /><span>Data Management</span></a>,
          <a key="2" href=""><i className="fa fa-fw fa-map-marker" /><span>Location</span></a>,
          <a key="3" href=""><i className="fa fa-fw fa-mortar-board" /><span>Study</span></a>,
          <a key="4" href=""><i className="fa fa-fw fa-picture-o" /><span>Collections</span></a>,
          <a key="5" href=""><i className="fa fa-fw fa-money" /><span>Credits</span></a>
        ];
        break;
      default:
        items = [
          <a key="0" href=""><i className="fa fa-fw fa-star-o" /><span>Favorites</span></a>,
          <a key="1" href=""><i className="fa fa-fw fa-bell-o" /><span>Alerts</span></a>,
          <a key="2" href=""><i className="fa fa-fw fa-envelope-o" /><span>Messages</span></a>,
          <a key="3" href=""><i className="fa fa-fw fa-comment-o" /><span>Comments</span></a>,
          <a key="4" href=""><i className="fa fa-fw fa-bar-chart-o" /><span>Analytics</span></a>,
          <a key="5" href=""><i className="fa fa-fw fa-newspaper-o" /><span>Reading List</span></a>
        ];
    }

    return items;
  }

  getMenu() {
    const Menu = BurgerMenu[this.state.currentMenu];
    var stylesmenu = {
        bmBurgerButton: {
          position: 'fixed',
          width: '24px',
          height: '18px',
          left: '10px',
          top: '-10px',
        },
    }
    return (
      <MenuWrap wait={20} side={this.state.side}>
        <Menu id={this.state.currentMenu} pageWrapId={'page-wrap'} outerContainerId={'outer-container'} right={this.state.side === 'right'} style={stylesmenu}>
          {/* <h2 key="0">
            <i className="fa fa-fw fa-inbox fa-2x" /><span>Obelisco</span>
            </h2> */}
          <LinkMenu/>
        </Menu>
      </MenuWrap>
    );
  }

  render() {
    // const buttons = Object.keys(this.props.menus).map((menu) => {
    //   return (
    //     <a key={menu}
    //       className={classNames({'current-demo': menu === this.state.currentMenu})}
    //       onClick={this.changeMenu.bind(this, menu)}>
    //       {this.props.menus[menu].buttonText}
    //     </a>
    //   );
    // });

    return (
      <div id="outer-container" style={{height: '100%'}}>
        {this.props.viewSideBar && this.getMenu()}
        <main id="page-wrap" className='mainpage'>
          {this.props.children}
        </main>
      </div>
    );
  }
}


export default MenuSide;
// ReactDOM.render(<Demo menus={menus} />, document.getElementById('app'));
