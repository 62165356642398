import React from 'react';
import '../../assets/css/general.css';

export default function Titulo(props) {
    const uppercase = props.uppercase
    const styleUpper = {marginTop: '14px', marginBottom: '14px', textTransform: 'uppercase'}  
    const styleNormal = {marginTop: '14px', marginBottom: '14px'} 
    return (
      <h2 style={uppercase? styleUpper : styleNormal} className="fnt_medium fnt_18">{props.text}</h2>
      );
}