import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import UsuarioDatos from './UsuarioDatos'
import UsuarioAsignacion from './UsuarioAsignacion'
import BtnAdd from '../../components/forms/BtnAdd'
import Titulo from '../../components/general/Titulo'


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  }
}));

export default function RegistroLogs() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div style={{ padding:'0 10px'}}>
      <div className="contenido max_360">
      <Titulo text="Logs"/>
        <div className={classes.root}>
          <p>Contenido Logs</p>
        </div>
        <BtnAdd/>
      </div>
    </div>
  );
}
