import React from 'react';
import { COLORS, __Uri} from '../../components/general/Constants';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import Titulo from '../../components/general/Titulo'
import PermIdentityIcon from '@material-ui/icons/PermIdentity'
import Subtitulo from '../../components/general/Subtitulo'
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import AddIcon from '@material-ui/icons/Add';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import ApiToken from '../../util/ApiToken';
import Swal from 'sweetalert2'

const filter = createFilterOptions();
class AsignarData extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        id : null,
        valor: null,
        lista_include : [],
        lista_exclude : [],
        view : false,
        lista_selected : [],
      };
    }
    componentWillMount = () => {
      this.setState({id:this.props.keyID})
      this._fetchGetListAsign()
    }
    _fetchGetListAsign = () => {
      let _Self = this
      ApiToken.request('put','asignacion/get', {keyID : this.props.keyID}, function(res){
          let temp = []
          if(res.response){
            _Self.setState({lista_include : [...res.data.included]})
            res.data.excluded.forEach(item => {
              item.data.forEach(subitem => {
                temp.push({
                  ...subitem,
                  title: item.title,
                  tipo: item.tipo
                })
              })
            })
            _Self.setState({lista_exclude : [...temp]})
            _Self.setState({view : true})
          }
      })
    }
    _fetchSaveAsign = (e) => {
      let _Self = this
      Swal.fire({
        text: '¿Desea asignar los items seleccionados?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: `Aceptar`,
        cancelButtonText: `Cancelar`,
        customClass:{
            popup:'bg_dark',
            confirmButton: 'btn_success',
            denyButton:'btn_deny',
            cancelButton:'btn_cancel',
            htmlContainer:'text_white',
            title:'text_white',
        }
      }).then((result) => {
          if (result.isConfirmed) {
            ApiToken.request('post','asignacion', {
              keyID : _Self.state.id,
              lista : _Self.state.lista_selected,
            }, function(res){
              if(res.response){
                _Self.setState({lista_selected : []})
                _Self.setState({clear : true})
                _Self._fetchGetListAsign()
                Swal.fire( '', res.message, 'success' )
              }else{
                Swal.fire( '¡Error!', res.message, 'success' )
              }
            })
          }
      })
    }
    _fetchDeleteItem = (_id, _type) => {
      let _Self = this
      Swal.fire({
        text: '¿Desea eliminar el item seleccionado?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: `Aceptar`,
        cancelButtonText: `Cancelar`,
        customClass:{
            popup:'bg_dark',
            confirmButton: 'btn_success',
            denyButton:'btn_deny',
            cancelButton:'btn_cancel',
            htmlContainer:'text_white',
            title:'text_white',
        }
      }).then((result) => {
          if (result.isConfirmed) {
            ApiToken.request('post','asignacion/delete', {
              keyID : _Self.state.id,
              itemID : _id,
              tipo : _type,
            }, function(res){
              if(res.response){
                _Self._fetchGetListAsign()
                Swal.fire( '', res.message, 'success' )
              }else{
                Swal.fire( '¡Error!', res.message, 'success' )
              }
            })
          }
      })
    }
    render(){
        const darkTheme = createMuiTheme({
          palette: {
            type: 'dark',
            secondary: {
              light: '#ffffff',
              main: '#788ba5',
              dark: '#546e91',
              contrastText: '#fff',
            }
          },
          secondary: {
              light: '#ffffff',
              main: '#788ba5',
              dark: '#546e91',
              contrastText: '#fff',
            }
        });
        const lista= {
          flexGrow: 1,
          overflow: 'hidden',
          padding: '10px 0',
        };
        const listSection= {
          backgroundColor: 'inherit',
        };
        const ul= {
          backgroundColor: 'inherit',
          padding: 0,
        };
        return this.state.view && (
            <div style={{ padding:'0 10px'}}>
                <div className="contenido max_360">
                    {/* <Titulo text="Pablo Piccaso"/> */}
                    <form>
                        <ThemeProvider theme={darkTheme}>
                          <Grid container spacing={1}>
                              <Grid item xs={10}>
                                <Autocomplete
                                    className="input_dark"
                                    multiple
                                    id="tags-outlined"
                                    options={this.state.lista_exclude}
                                    getOptionLabel={(option) => option.nombre}
                                    filterSelectedOptions
                                    value = {this.state.lista_selected}
                                    onChange={(event, value) => {
                                      this.setState({lista_selected : [...value]})
                                    }}
                                    groupBy={(option) => option.title}
                                    clearOnBlur={this.state.clear}
                                    renderInput={(params) => (
                                      <TextField
                                          {...params}
                                          variant="outlined"
                                          label="Añadir acceso"
                                          placeholder="Añadir"
                                          className="input_dark"
                                          color="secondary"
                                      />
                                    )}
                                />
                              </Grid>
                              <Grid item xs={2}>
                                <Button variant="contained" color="secondary" href="#title_section-1" className="full_content" style={{padding:'15px 0'}} onClick={this._fetchSaveAsign}>
                                    <AddIcon/>
                                </Button>
                              </Grid>
                            </Grid>

                            <section className="section_form">
                                <div id="title_section-1">
                                    <PermIdentityIcon style={{ fontSize: 16, color:'#cccccc' }} className="inline" />
                                    <Subtitulo uppercase text="Grupos asignados"/>
                                </div>
                              <List  subheader={<li />}>
                                  <li key={`section-A`} className={listSection}>
                                    <ul className={ul}>
                                    {
                                      this.state.lista_include.map(item => (
                                        <div key={item.tipo}>
                                          <ListSubheader>{item.title}</ListSubheader>
                                          {item.data.length == 0 && (
                                            <Typography component="div" variant="body1">
                                              <Box color="text.disabled" fontStyle="italic" fontSize={12}>No existen asignaciones.</Box>
                                            </Typography>
                                          )}
                                          {item.data.map((subitem) => (
                                            <ListItem key={`item-rol-${subitem.id}`}>
                                              <ListItemText primary={subitem.nombre} />
                                              <ListItemSecondaryAction>
                                                <IconButton edge="end" aria-label="delete">
                                                  <DeleteIcon  onClick={(e)=>{this._fetchDeleteItem(subitem.id, item.tipo)}}/>
                                                </IconButton>
                                              </ListItemSecondaryAction>
                                            </ListItem>
                                          ))}
                                        </div>
                                      ))
                                    }
                                    </ul>
                                  </li>
                              </List>

                            </section>

                        </ThemeProvider>
                    </form>

                </div>
            </div>
        )

    }
}
export default AsignarData;
