import React from 'react';
import { COLORS, __Uri} from '../../components/general/Constants';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import PermIdentityIcon from '@material-ui/icons/PermIdentity'
import Subtitulo from '../../components/general/Subtitulo'
import Grid from '@material-ui/core/Grid';
import iconSave from '../../assets/img/icon_save.svg';
import ListadoPart from '../../components/general/ListadoPart'
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import AccountCircle from '@material-ui/icons/AccountCircle';
import InputFecha from '../../components/forms/InputFecha'
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import SimpleSelect from '../../components/forms/SimpleSelect'
import EditLocationIcon from '@material-ui/icons/EditLocation';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import LockIcon from '@material-ui/icons/Lock';
import { withRouter } from 'react-router-dom';
import ApiToken from '../../util/ApiToken';
import Swal from 'sweetalert2'
const filter = createFilterOptions();

class CrearData extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id : this.props.match.params.hasOwnProperty('id') ? this.props.match.params.id : null,
            nombre : '',
            primer_apellido : '',
            segundo_apellido : '',
            fecha_nacimiento : '',
            telefono : '',
            celular : '',
            email : '',
            direccion : '',
            genero : '',
            view_password : false,
            password : '',
            confirm : '',
            view : false,
        };
    }
    componentWillMount = () => {
      if(this.state.id != null){
        let _Self = this
        ApiToken.request('put','usuario/edit', {keyID : _Self.state.id}, function(res){
          if(res.response){
            _Self.setState({telefono : res.data.telefono})
            _Self.setState({celular : res.data.celular})
            _Self.setState({email : res.data.email})
            _Self.setState({direccion : res.data.direccion})
            _Self.setState({genero : res.data.genero})
            _Self.setState({nombre : res.data.nombre})
            _Self.setState({primer_apellido : res.data.primer_apellido})
            _Self.setState({segundo_apellido : res.data.segundo_apellido})
            _Self.setState({fecha_nacimiento : res.data.fecha_nacimiento})
            _Self.props.setUserName([res.data.nombre,res.data.primer_apellido,res.data.segundo_apellido].join(' '))
            _Self.setState({view : true})
          }else{
            Swal.fire({
              text: res.message,
              icon: 'error',
              confirmButtonText: `OK`,
              customClass:{
                  popup:'bg_dark',
                  confirmButton: 'btn_success',
              }
            })
          }

        })
      }else{
        this.setState({view : true})
      }
    }
    _fetchCancel = (e) => {
      e.preventDefault()
      this.props.history.push(`/sistem-usuarios/gestion#usuarios`)
    }
    _fetchValidate = () => {
      let sw = true;
      let validate = {
        nombre : true,
        primer_apellido : true,
        fecha_nacimiento : true,
        email : true,
        contraseña : true,
      }
      let data = {...this.state}
      for(let prop in validate)
        sw = sw && ( data[prop] != '' )
      sw = sw && (this.state.telefono != '' || this.state.celular != '')
      if(this.state.view_password || this.state.id == null){
        sw = sw && (this.state.password != '')
        sw = sw && (this.state.password == this.state.confirm)
      }
      return sw
    }
    fetchDeletUser = () => {
      let _Self = this
      Swal.fire({
        text: '¿Desea eliminar el este usuario?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: `Aceptar`,
        cancelButtonText: `Cancelar`,
        customClass:{
            popup:'bg_dark',
            confirmButton: 'btn_success',
            denyButton:'btn_deny',
            cancelButton:'btn_cancel',
            htmlContainer:'text_white',
            title:'text_white',
        }
      }).then((result) => {
          if (result.isConfirmed) {
            ApiToken.request('post','usuario/delete', {
              keyID : _Self.state.id,
            }, function(res){
              if(res.response){
                Swal.fire( '', res.message, 'success' )
                .then(result => {
                  _Self.props.history.push(`/sistem-usuarios/gestion`)
                })
              }else{
                Swal.fire( '¡Error!', res.message, 'success' )
              }
            })
          }
      })
    }
    _fetchSubmitRequest = (e) => {
      e.preventDefault()
      let _Self = this
      if(_Self._fetchValidate()){
        ApiToken.request('post',`usuario${this.state.id == null ? '' : '/update'}`, {..._Self.state}, function(res){
          if(res.response){
            _Self.setState({id : res.keyID})
            _Self.setState({password : ''})
            _Self.setState({confirm : ''})
            Swal.fire({
              text: res.message,
              icon: 'success',
              confirmButtonText: `OK`,
              customClass:{
                  popup:'bg_dark',
                  confirmButton: 'btn_success',
              }
            }).then((result) => {
              _Self.props.history.replace(`/sistem-usuarios/usuario/${res.keyID}/editar`)
            })
          }else{
            Swal.fire({
              text: res.message,
              icon: 'error',
              confirmButtonText: `OK`,
              customClass:{
                  popup:'bg_dark',
                  confirmButton: 'btn_success',
              }
            })
          }
        })
      }
    }
    render(){
          const darkTheme = createMuiTheme({
            palette: {
              type: 'dark',
              secondary: {
                light: '#ffffff',
                main: '#788ba5',
                dark: '#546e91',
                contrastText: '#fff',
              }
            },
            secondary: {
                light: '#ffffff',
                main: '#788ba5',
                dark: '#546e91',
                contrastText: '#fff',
              }
          });
        return this.state.view && (
            <div style={{ padding:'0 10px'}}>
                <div className="contenido max_360">
                    <form onSubmit={(e) => {this._fetchSubmitRequest(e)}}>
                        <ThemeProvider theme={darkTheme}>
                            <section className="section_form">
                                <div id="title_section-1">
                                    <PermIdentityIcon style={{ fontSize: 16, color:'#cccccc' }} className="inline" />
                                    <Subtitulo uppercase text="Datos Personales"/>
                                </div>
                                <Grid container spacing={1}>
                                  <Grid item xs={6}>
                                    <TextField id="nombre"
                                      label="Nombre"
                                      variant="outlined"
                                      margin="normal"
                                      fullWidth
                                      className="input_dark"
                                      color="secondary"
                                      onChange = {(e)=>{this.setState({nombre : e.target.value})}}
                                      value = {this.state.nombre}
                                      required = { true }
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment position="start">
                                            <AccountCircle />
                                          </InputAdornment>
                                        ),
                                      }}
                                    />
                                  </Grid>
                                  <Grid item xs={6}>
                                    <TextField id="email"
                                        label="Email"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        className="input_dark"
                                        color="secondary"
                                        type="email"
                                        onChange = {(e)=>{this.setState({email : e.target.value})}}
                                        value = {this.state.email}
                                        required = { true }
                                        InputProps={{
                                          endAdornment: (
                                            <InputAdornment position="start">
                                              <EmailIcon />
                                            </InputAdornment>
                                          ),
                                        }}
                                        />
                                  </Grid>
                                  <Grid item xs={6}>
                                    <TextField id="apellido1"
                                      label="Apellido 1"
                                      variant="outlined"
                                      margin="normal"
                                      fullWidth
                                      className="input_dark"
                                      color="secondary"
                                      onChange = {(e)=>{this.setState({primer_apellido : e.target.value})}}
                                      value = {this.state.primer_apellido}
                                      required = { true }
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment position="start">
                                            <AccountCircle />
                                          </InputAdornment>
                                        ),
                                      }}
                                      />
                                  </Grid>
                                  <Grid item xs={6}>
                                    <TextField id="apellido2"
                                      label="Apellido 2"
                                      variant="outlined"
                                      margin="normal"
                                      fullWidth
                                      className="input_dark"
                                      color="secondary"
                                      onChange = {(e)=>{this.setState({segundo_apellido : e.target.value})}}
                                      value = {this.state.segundo_apellido}
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment position="start">
                                            <AccountCircle />
                                          </InputAdornment>
                                        ),
                                      }}
                                      />
                                  </Grid>
                                  <Grid item xs={6}>
                                    <TextField id="telefono"
                                      label="Teléfono"
                                      variant="outlined"
                                      margin="normal"
                                      fullWidth
                                      className="input_dark"
                                      color="secondary"
                                      onInput = {(e) => { e.target.value = ApiToken.methods.ValToNumber(e.target) }}
                                      onChange = {(e)=>{this.setState({telefono : e.target.value})}}
                                      value = {this.state.telefono}
                                      required = {this.state.celular == ''}
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment position="start">
                                            <PhoneIcon />
                                          </InputAdornment>
                                        ),
                                      }}
                                    />
                                  </Grid>
                                  <Grid item xs={6}>
                                    <TextField id="celular"
                                      label="Celular"
                                      variant="outlined"
                                      margin="normal"
                                      fullWidth
                                      className="input_dark"
                                      color="secondary"
                                      onInput = {(e) => { e.target.value = ApiToken.methods.ValToNumber(e.target) }}
                                      onChange = {(e)=>{this.setState({celular : e.target.value})}}
                                      value = {this.state.celular}
                                      required = {this.state.telefono == ''}
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment position="start">
                                            <PhoneAndroidIcon />
                                          </InputAdornment>
                                        ),
                                      }}
                                      />
                                  </Grid>
                                  <Grid item xs={6}>
                                      <InputFecha
                                        label="Nacimiento"
                                        required = { true }
                                        defaultValue = {this.state.fecha_nacimiento}
                                        setValue = {(value) => {this.setState({fecha_nacimiento : value})}}
                                      />
                                  </Grid>
                                  <Grid item xs={6}>
                                      <SimpleSelect
                                        label="Genero"
                                        opciones={[{value : 'M', text : 'Masculino'},{value : 'F', text : 'Femenino'}]}
                                        id="simpleselect-label-outlined-Cantidad"
                                        idLabel="simpleselect-label-Cantidad"
                                        defaultValue = {this.state.genero}
                                        setValue = {(val)=>{this.setState({genero : val})}}
                                        required = { true }
                                      />
                                  </Grid>
                                  <TextField id="domicilio"
                                      label="Domicilio"
                                      variant="outlined"
                                      margin="normal"
                                      fullWidth
                                      className="input_dark"
                                      color="secondary"
                                      value = {this.state.direccion}
                                      onChange = {(e) => {this.setState({direccion : e.target.value})}}
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment position="start">
                                            <EditLocationIcon />
                                          </InputAdornment>
                                        ),
                                      }}
                                      />
                                  {this.state.id != null && (<FormControlLabel
                                    value="0"
                                    control={
                                      <Checkbox
                                          color="primary"
                                          checked = {this.state.view_password}
                                          onChange = {(e) => {this.setState({view_password : e.target.checked})}}
                                      />
                                    }
                                    label="Modificar contraseña"
                                    labelPlacement="Modificar contraseña"
                                  />)}
                                  {
                                    (this.state.view_password || this.state.id == null) ?
                                    (
                                      <div>
                                        <TextField
                                            id="newpassword"
                                            label="Nueva Contraseña"
                                            variant="outlined"
                                            margin="normal"
                                            type="password"
                                            fullWidth
                                            className="input_dark"
                                            color="secondary"
                                            value = {this.state.password}
                                            onChange = {(e) => {this.setState({password : e.target.value})}}
                                            required = {(this.state.view_password || this.state.id == null)}
                                            InputProps={{
                                              endAdornment: (
                                                <InputAdornment position="start">
                                                  <LockIcon />
                                                </InputAdornment>
                                              ),
                                            }}
                                        />
                                        <TextField
                                            id="newpasswordconf"
                                            label="Confirmar Contraseña"
                                            variant="outlined"
                                            margin="normal"
                                            type="password"
                                            fullWidth
                                            className="input_dark"
                                            color="secondary"
                                            value = {this.state.confirm}
                                            onChange = {(e) => {this.setState({confirm : e.target.value})}}
                                            required = {(this.state.view_password || this.state.id == null)}
                                            helperText={(this.state.password != this.state.confirm) ? 'No coinciden las contraseñas.' : ''}
                                            error = {this.state.password != this.state.confirm}
                                            InputProps={{
                                              endAdornment: (
                                                <InputAdornment position="start">
                                                  <LockIcon />
                                                </InputAdornment>
                                              ),
                                            }}
                                        />
                                      </div>
                                    ) : ''
                                  }
                                </Grid>
                            </section>
                            <section className="section_form">
                                <Grid container spacing={1}>
                                    <Grid item xs={6}>
                                      <Button variant="contained" color="secondary" className="full_content" type="submit">
                                          Guardar
                                      </Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                      <Button variant="contained" color="secondary" className="full_content" onClick={(e)=>{this._fetchCancel(e)}}>
                                          Cancelar
                                      </Button>
                                    </Grid>
                                    {
                                      this.state.id != null ?
                                      (
                                        <Grid item xs={12}>
                                          <Button variant="contained" color="tertiary" className="full_content" onClick={this.fetchDeletUser}>
                                            Eliminar
                                          </Button>
                                        </Grid>
                                      ) : ''
                                    }
                                </Grid>
                            </section>
                        </ThemeProvider>
                    </form>
                </div>
            </div>
        )

    }
}
export default withRouter(CrearData);
