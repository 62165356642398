import React from 'react';
import { COLORS, __Uri} from '../general/Constants';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    height: 150,
    margin: '15px 0',
    //maxWidth: 300,
    width: '100%',
    overflowY: 'scroll',
    //border: '1px solid #3d4755',
    //borderRadius: 10,
    fontSize:'inherit',
  },
  demo: {
    background: COLORS.bg_paper,

  },
  list: {
      borderBottom: '1px solid #3d4755',
      //fontSize: 14,
  },
}));

const IconEditRemove = (props) => {
  return (
    <ListItemSecondaryAction>
      <IconButton edge="end" aria-label="delete" color="secondary" >
        <EditIcon fontSize="small"/>
      </IconButton>
      <IconButton edge="end" aria-label="delete" color="secondary" >
        <CloseIcon fontSize="small"/>
      </IconButton>
    </ListItemSecondaryAction>
  );
}
const IconRemove = (props) => {
  return (
    <ListItemSecondaryAction>
      <IconButton edge="end" aria-label="delete" color="secondary" onClick={(e)=>{props.actionRemove()}}>
        <CloseIcon fontSize="small"/>
      </IconButton>
    </ListItemSecondaryAction>
  );
}

/*function renderRow(props) {
  const { premio ,cant, style } = props;

  return (
    <ListItem button style={style} key={premio}>
      <ListItemText primary={`Item ${cant + 1}`} />
    </ListItem>
  );
}*/

// const renderItem = (props) => {
//     return (
//       <ListItem //className={this.classes.list}
//     >
//       <ListItemText
//         primary="Single-line item"
//         className="fnt_14"
//         secondary="Text secondary" //{this.secondary ? 'Secondary text' : null}
//       />
//       {/* { (this.props.edit == 'remove')? <IconRemove/>: <IconEditRemove/> } */}
//     </ListItem>
//     );
//   }
// renderRow.propTypes = {
//   cant: PropTypes.number.isRequired,
//   style: PropTypes.object.isRequired,
// };
function generate(element) {
    return [0, 1, 2].map((value) =>
      React.cloneElement(element, {
        key: value,
      }),
    );
  }

export default function ListadoPart(props) {
  const classes = useStyles();
  const [dense, setDense] = React.useState(false);
  const [secondary, setSecondary] = React.useState(false);
  const [lista, setLista] = React.useState(props.lista);
  return (
    <div className={classes.root}>
      <Grid item
      //xs={12} md={6}
      >
          <div className={classes.demo}>
            <List dense={dense}
            >
              {lista.map(item=>(
                <ListItem className={classes.list} key={item.id}>
                  <ListItemText
                  primary={item.nombre}
                  className="fnt_14"
                  //secondary={this.secondary ? 'Secondary text' : null}
                  />
                  {(props.remove)
                    ? <IconRemove
                      actionRemove={(e) => {props.removeAssignment(item.id)}}
                    />
                    : null
                  }
                  {(props.edit)
                    ? <IconEditRemove/>
                    : null
                  }
                </ListItem>
              ))}
            </List>
          </div>
        </Grid>
    </div>
  );
}
