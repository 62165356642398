import React from 'react';
import '../../assets/css/general.css';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import { COLORS, __Uri} from '../../components/general/Constants';
import Link from '@material-ui/core/Link';

export default function Logs(props) {
    return (
        <Link component="a" className="LinkItem verlogs fnt_14" style={{color: COLORS.text_danger}} href={props.ruta}><MenuBookIcon style={{fontSize:'16px', verticalAlign:'bottom'}}/> Ver Logs</Link>
    );
}
