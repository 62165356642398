import React, { useEffect, useState } from 'react';
import { COLORS } from '../../components/general/Constants';
import { makeStyles } from '@material-ui/core/styles';
import LoginLogo from '../../components/forms/LoginLogo'
import Popup from './popupRecuperarContrasena'
import FormLoginDorado from '../../components/forms/FormLoginDorado'
import { NavLink } from 'react-router-dom';
import { createMuiTheme } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles'
import logo from '../../assets/img/logo_obelisco.svg';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import LockIcon from '@material-ui/icons/Lock';
import TextField from '@material-ui/core/TextField';
import MailOutline from '@material-ui/icons/MailOutline';
import ApiToken from '../../util/ApiToken';
import { Alert, AlertTitle } from '@material-ui/lab';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@material-ui/icons/Close';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#9f8e6e',
    },
    secondary: {
      main: '#bdad90',
    },
  },
  typography: {
    fontFamily: 'Raleway, Arial',
  },
});
const useStyles = makeStyles((theme) => ({
	root: {
  		margin: theme.spacing(1),
  		width: '260px',
	},
	margin: {
		margin: '20px 0',
		},
}));
function Login(props){
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [open, setOpen] = useState(false)
    const [alert, setAlert] = useState('')
    const classes = useStyles();
    const stylebg_icon = {
        background: COLORS.bg_icon_lg,
    };
    const _fetchSubmit = (e) => {
      e.preventDefault()
      ApiToken.request('post','login',{
        email : email,
        password : password,
      }, function(res){
        if(res.response){
          props.onSetLogin(res.user)
        }else{
          setOpen(true)
          setAlert(res.message)
        }
      })
    }
    var icon_style= {margin: '16px 0'}
    var text_color = {color: '#fff'}
    return (
		<ThemeProvider theme={theme}>
			<div  className="App">
					<header className="App-header">
          <Collapse in={open}>
          <Alert
          severity="error"
          action={
            <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              setOpen(false);
            }}
            >
            <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          >
          <strong>¡Error!</strong> {alert}
          </Alert>
          </Collapse>
            <LoginLogo logo={logo} />
						<h6>LOGIN</h6>
						<div className="form_login">
				    <form className={classes.root} noValidate autoComplete="off" onSubmit={ _fetchSubmit }>
		  				<div className={classes.margin}>
		        			<Grid container spacing={0} alignItems="center">
							    <Grid item style={stylebg_icon} className="border_input cont_icon">
			            			<MailOutline style={icon_style}/>
			          			</Grid>
			          			<Grid item style={{width: 'calc(100% - 50px)'}}>
			            			<TextField
                            id="usuario"
                            label="Usuario"
                            variant="outlined"
                            size="normal"
                            className="border_input"
                            type="email"
                            value={email}
                            onChange={(e)=>{setEmail(e.target.value)}}
                        />
			          			</Grid>
		        			</Grid>
		      			</div>

		      			<div className={classes.margin}>
		        			<Grid container spacing={0} alignItems="center">
							    <Grid item style={stylebg_icon} justify="center" className="border_input cont_icon">
			            			<LockIcon style={icon_style} />
			          			</Grid>
			          			<Grid item style={{width: 'calc(100% - 50px)'}}>
			            			<TextField
                            id="password"
                            color="primary"
                            label="Contraseña"
                            type="password"
                            variant="outlined"
                            size="normal"
                            className="border_input"
                            value={password}
                            onChange={(e)=>{setPassword(e.target.value)}}
                        />
			          			</Grid>
		        			</Grid>
		      			</div>
		      			<div>
		      				<Button className="botom_login" style={text_color} variant="contained" color="secondary" type="submit">
		        				ENTRAR
		      				</Button>
		      			</div>

      				</form>
      			</div>
						<p className="aling_text">
						<Popup />
						<NavLink to="/Registro" className="texto_login link_a">Registrate</NavLink>
						</p>
					</header>
			</div>
	   	</ThemeProvider>
    );
}
export default Login;
