import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import UsuarioDatos from './UsuarioDatos'
import UsuarioAsignacion from './UsuarioAsignacion'
import BtnAdd from '../../components/forms/BtnAdd'
import Titulo from '../../components/general/Titulo'



function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  OpTab:{
    padding: 0
  }
}));

export default function SimpleTabs(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [title, setTitle] = React.useState('Nuevo Usuario');
  const keyID = props.match.params.hasOwnProperty('id') ? props.match.params.id : null
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div style={{height: 'max-content', padding:'0 10px'}}>
      <div className="contenido max_360">
      <Titulo text={title}/>
        <div className={classes.root}>
          {/* <AppBar position="static" > */}
            <Tabs value={value} className="nobackground tabsfull" onChange={handleChange} aria-label="simple tabs example">
              <Tab label="Datos" {...a11yProps(0)} />
              { keyID != null && (<Tab label="Asignación" {...a11yProps(1)} />)}
            </Tabs>
          {/* </AppBar> */}
          <TabPanel value={value} index={0} className="OpTab">
            <UsuarioDatos setUserName={setTitle}/>
          </TabPanel>
          { keyID != null && (
            <TabPanel value={value} index={1} className="OpTab">
              <UsuarioAsignacion keyID={keyID}/>
            </TabPanel>
          )}
        </div>
      </div>
    </div>
  );
}
