import React from 'react';
import { COLORS, __Uri} from '../../components/general/Constants';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';

import { withStyles } from '@material-ui/core/styles';
import ListItem from '../../components/general/ListItem'
import Titulo from '../../components/general/Titulo'
import Subtitulo from '../../components/general/Subtitulo'
import StorageIcon from '@material-ui/icons/Storage';
import ListAltIcon from '@material-ui/icons/ListAlt';

import Grid from '@material-ui/core/Grid';
import iconSave from '../../assets/img/icon_save.svg';
import ListadoPart from '../../components/general/ListadoPart'
import Button from '@material-ui/core/Button';
import { withRouter } from 'react-router-dom';
import ApiToken from '../../util/ApiToken';
import Swal from 'sweetalert2'

const filter = createFilterOptions();
const data = [
  {
    uri : 'user',
    uriList : '',
    uriPath : '#usuarios',
    sistema : false,
    title : 'Crear Usuario',
  },{
    uri : 'rol',
    uriList : 'rol/user',
    uriPath : '#roles',
    sistema : true,
    title : 'Crear Rol',
  },{
    uri : 'grupo-rol',
    uriList : 'grupo-rol/rol',
    uriPath : '#grupo-rol',
    sistema : true,
    title : 'Crear Grupo Rol',
  },{
    uri : 'grupo-usuario',
    uriList : 'grupo-usuario/user',
    uriPath : '#grupo-usuario',
    sistema : false,
    title : 'Crear Grupo Usuario',
  },
]
class CrearData extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
          id : this.props.match.params.hasOwnProperty('id') ? this.props.match.params.id : null,
          data : {
            ...data[this.props.index],
            index : this.props.index
          },
          id_sistema: '',
          nombre: '',
          descripcion: '',
          view : false,
          sistema: null,
          value: null,
          lista_sistema: [],
          lista_include: [],
          lista_exclude: [],
          item:null,
      };
    }
    componentWillMount = async () => {
      let _Self = this
      let lista = []
      if(this.state.id != null){
        ApiToken.request('put',`${this.state.data.uri}/edit`,{keyID:_Self.state.id}, function(res){
          if(res.response){
            _Self.setState({id_sistema: res.data.id_sistema})
            _Self.setState({nombre: res.data.nombre})
            _Self.setState({descripcion: res.data.descripcion})
            _Self.setState({view : true})
            _Self.setState({data : {
              ..._Self.state.data,
              title : res.data.nombre
            }})
            _Self._fetchGetLisUser()
            if(_Self.state.data.sistema){
              ApiToken.request('put','sistema/get',{}, function(_res){
                if(_res.response){
                  lista = []
                  _Self.setState({lista_sistema: _res.list.map(sis => ({id: sis.id, nombre: sis.nombre}))})
                  _res.list.forEach(item => {
                    if(item.id == res.data.id_sistema){
                      _Self.setState({sistema : {id: item.id, nombre: item.nombre}})
                    }
                  })
                }
              })
            }
          }
        })
      }else{
        console.log(this.state.data, this.props)
        if(this.state.data.sistema){
          ApiToken.request('put','sistema/get',{}, function(_res){
            if(_res.response){
              lista = []
              _Self.setState({lista_sistema: _res.list.map(sis => ({id: sis.id, nombre: sis.nombre}))})
            }
          })
        }
        this.setState({view : true})
      }
    }
    _fetchGetLisUser = () => {
      let _Self = this
      ApiToken.request('put',`${_Self.state.data.uriList}/get`,{keyID:_Self.state.id}, function(res){
        if(res.response){
          _Self.setState({lista_exclude:res.data.exclude})
          _Self.setState({lista_include:res.data.include})
        }
      })
    }
    _fetchDeletAsign = (_data) => {
      let _Self = this
      if(_data == null) return
      Swal.fire({
        text: `¿Desea eliminar la asignacion del ${this.state.data.uri} al item seleccionado?`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: `Aceptar`,
        cancelButtonText: `Cancelar`,
        customClass:{
            popup:'bg_dark',
            confirmButton: 'btn_success',
            denyButton:'btn_deny',
            cancelButton:'btn_cancel',
            htmlContainer:'text_white',
            title:'text_white',
        }
      }).then((result) => {
        if (result.isConfirmed) {
          _Self.setState({lista_exclude:[]})
          _Self.setState({lista_include:[]})
          ApiToken.request('post',`${this.state.data.uriList}/delete`,{
              keyID: _Self.state.id,
              itemID: _data,
            }, function(res){
            if(res.response){
              _Self._fetchGetLisUser()
              Swal.fire({
                text: res.message,
                icon: 'success',
                confirmButtonText: `OK`,
                customClass:{
                    popup:'bg_dark',
                    confirmButton: 'btn_success',
                }
              })
            }else{
              Swal.fire({
                text: res.message,
                icon: 'error',
                confirmButtonText: `OK`,
                customClass:{
                    popup:'bg_dark',
                    confirmButton: 'btn_success',
                }
              })
            }
          })
        }
      })
    }
    _fetchSaveAsign = () => {
      let _Self = this
      if(_Self.state.item == null) return
      Swal.fire({
        text: `¿Desea asignar este ${this.state.data.uri} al item ${_Self.state.item.nombre}?`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: `Aceptar`,
        cancelButtonText: `Cancelar`,
        customClass:{
            popup:'bg_dark',
            confirmButton: 'btn_success',
            denyButton:'btn_deny',
            cancelButton:'btn_cancel',
            htmlContainer:'text_white',
            title:'text_white',
        }
      }).then((result) => {
        if (result.isConfirmed) {
          _Self.setState({lista_exclude:[]})
          _Self.setState({lista_include:[]})
          ApiToken.request('post',`${this.state.data.uriList}`,{
              keyID: _Self.state.id,
              itemID: _Self.state.item.id,
            }, function(res){
            if(res.response){
              _Self._fetchGetLisUser()
              Swal.fire({
                text: res.message,
                icon: 'success',
                confirmButtonText: `OK`,
                customClass:{
                    popup:'bg_dark',
                    confirmButton: 'btn_success',
                }
              })
              _Self.setState({item:null})
            }else{
              Swal.fire({
                text: res.message,
                icon: 'error',
                confirmButtonText: `OK`,
                customClass:{
                    popup:'bg_dark',
                    confirmButton: 'btn_success',
                }
              })
            }
          })
        }
      })
    }
    _fetchCancel = (e) => {
      e.preventDefault()
      this.props.history.push(`/sistem-usuarios/gestion${this.state.data.uriPath}`)
    }
    _fetchSubmitRequest = (e) => {
      e.preventDefault()
      let _Self = this
      let sw = true;
      let validate = {
        nombre: true,
        descripcion: false,
        id_sistema: this.state.data.sistema,
      }
      let data = {...this.state}
      for(let prop in validate){
        if(validate[prop]) sw = sw && ( (`${data[prop]}`).length > 0 )
      }
      if(sw){
        ApiToken.request('post',`${this.state.data.uri}${this.state.id == null ? '' : '/update'}`,{...data}, function(res){
          if(res.response){
            _Self.setState({id : res.keyID})
            _Self._fetchGetLisUser()
            Swal.fire({
              text: res.message,
              icon: 'success',
              confirmButtonText: `OK`,
              customClass:{
                  popup:'bg_dark',
                  confirmButton: 'btn_success',
              }
            }).then((result) => {
              _Self.props.history.replace(`/sistem-usuarios/${_Self.state.data.uri}/${res.keyID}/editar`)
            })
          }else{
            Swal.fire({
              text: res.message,
              icon: 'error',
              confirmButtonText: `OK`,
              customClass:{
                  popup:'bg_dark',
                  confirmButton: 'btn_success',
              }
            })
          }
        })
      }
    }
    _fetchRequestDelet = () => {
      let _Self = this
      Swal.fire({
        text: `¿Desea eliminar el este ${this.state.data.uri}?`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: `Aceptar`,
        cancelButtonText: `Cancelar`,
        customClass:{
            popup:'bg_dark',
            confirmButton: 'btn_success',
            denyButton:'btn_deny',
            cancelButton:'btn_cancel',
            htmlContainer:'text_white',
            title:'text_white',
        }
      }).then((result) => {
          if (result.isConfirmed) {
            ApiToken.request('post',`${this.state.data.uri}/delete`, {
              keyID : _Self.state.id,
            }, function(res){
              if(res.response){
                Swal.fire( '', res.message, 'success' )
                .then(result => {
                  _Self.props.history.push(`/sistem-usuarios/gestion${_Self.state.data.uriPath}`)
                })
              }else{
                Swal.fire( '¡Error!', res.message, 'error' )
              }
            })
          }
      })
    }
    render(){
          const darkTheme = createMuiTheme({
            palette: {
              type: 'dark',
              secondary: {
                light: '#ffffff',
                main: '#788ba5',
                dark: '#546e91',
                contrastText: '#fff',
              }
            },
            secondary: {
                light: '#ffffff',
                main: '#788ba5',
                dark: '#546e91',
                contrastText: '#fff',
              }
          });
        return this.state.view && (
            <div style={{ padding:'0 10px'}}>
                <div className="contenido max_360">
                    <Titulo text={this.state.data.title}/>
                    <ThemeProvider theme={darkTheme}>
                        <form onSubmit={(e) => {this._fetchSubmitRequest(e)}}>
                            <section className="section_form">
                                <div id="title_section-1">
                                    <StorageIcon style={{ fontSize: 16, color:'#cccccc' }} className="inline" />
                                    <Subtitulo uppercase text="Datos Generales"/>
                                </div>
                                <TextField id="nombre"
                                    label="Nombre"
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    className="input_dark"
                                    color="secondary"
                                    value={this.state.nombre}
                                    required={true}
                                    onChange={(e)=>{this.setState({nombre:e.target.value})}}
                                />
                                <TextField
                                    id="descripcion"
                                    label="Descripción"
                                    multiline
                                    rows={2}
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    className="input_dark"
                                    color="secondary"
                                    value={this.state.descripcion}
                                    onChange={(e)=>{this.setState({descripcion:e.target.value})}}
                                />
                                { this.state.data.sistema ? (
                                  <Autocomplete
                                      value={this.state.sistema}
                                      onChange={(event, newValue) => {
                                        this.setState({sistema:newValue})
                                        if(newValue != null) this.setState({id_sistema:newValue.id})
                                      } }
                                      selectOnFocus
                                      clearOnBlur
                                      handleHomeEndKeys
                                      id="free-solo-with-text-demo"
                                      options={this.state.lista_sistema}
                                      getOptionLabel={(option) => option.nombre}
                                      style={{ width: '100%' }}
                                      freeSolob
                                      renderInput={(params) => (
                                          <div style={{
                                              display: 'flex',
                                              alignItems: 'center',
                                          }}>
                                              <TextField {...params} label="Sistema" variant="outlined" className="input_dark"
                                              color="secondary" required={true}/>
                                          </div>
                                      )}
                                  />):('')}
                            </section>
                            <section className="section_form" style={{marginTop: '10px'}}>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                    <Button variant="contained" color="secondary" className="full_content" type="submit">
                                        Guardar
                                    </Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                    <Button variant="contained" color="secondary" className="full_content" onClick={this._fetchCancel}>
                                        Cancelar
                                    </Button>
                                    </Grid>
                                    <Grid item xs={12}>
                                    {
                                      this.state.id != null && (
                                        <Button variant="contained" color="tertiary" className="full_content" onClick={this._fetchRequestDelet}>
                                        Eliminar
                                        </Button>
                                      )
                                    }
                                    </Grid>
                                </Grid>
                            </section>
                        </form>
                        {
                          this.state.id != null && (
                            <section className="section_form">
                            <div id="title_section-2">
                              <ListAltIcon style={{ fontSize: 16, color:'#cccccc' }} className="inline" />
                              <Subtitulo uppercase text="Lista de asignación"/>
                            </div>
                            <Grid container spacing={1}>
                              <Grid item xs={10}>
                                <Autocomplete
                                // className="input_dark"
                                valor={this.item}
                                onChange={(event, newValue) => { this.setState({item:newValue}) }}
                                selectOnFocus
                                clearOnBlur
                                handleHomeEndKeys
                                id="free-solo-with-text-demo"
                                options={this.state.lista_exclude}
                                getOptionLabel={(option) => (option.nombre)}
                                renderOption={(option) => (option.nombre)}
                                style={{ width: '100%' }}
                                freeSolo
                                renderInput={(params) => (
                                  <div style={{
                                    padding: '2px 4px',
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}>
                                  <TextField {...params} label="Buscar..." variant="outlined" className="input_dark"
                                  color="secondary"/>
                                  </div>
                                )}
                              />
                              </Grid>
                              <Grid item xs={2}>
                                <IconButton size="large" color="secondary" aria-label="add an alarm" onClick={this._fetchSaveAsign}>
                                  <img className="iconSave" src={iconSave}/>
                                </IconButton>
                              </Grid>
                            </Grid>
                            {this.state.lista_include.length > 0 && (
                              <ListadoPart
                                lista={this.state.lista_include}
                                removeAssignment={this._fetchDeletAsign}
                                remove
                              />
                            )}
                            </section>
                          )
                        }
                    </ThemeProvider>
                </div>
            </div>
        )

    }
}
export default withRouter(CrearData);
