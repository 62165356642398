import React from 'react';
import {COLORS,__Uri} from './../general/Constants';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from "react-router-dom";
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import SendIcon from '@material-ui/icons/Send';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import StarBorder from '@material-ui/icons/StarBorder';
import AppsIcon from '@material-ui/icons/Apps';



const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.light,
    paddingTop: '30px'
  },
  nested: {
    paddingLeft: theme.spacing(4),
    boxShadow: 'none !important',
    padding: '15px 15px 10px 30px !important',
    textTransform: 'none !important',
    color: 'inherit !important',
  },
  linkitem:{
    textTransform: 'none !important',
    color: 'inherit !important',
  }
}));

export default function LinkMenu() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen(!open);
  };
  function ListItemLink(props) {
    return <ListItem button component="a" {...props} />;
  }
  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
    //   subheader={
    //     <ListSubheader component="div" id="nested-list-subheader">
    //       Nested List Items
    //     </ListSubheader>
    //   }
      className={classes.root}
    >
      <ListItemLink href={__Uri.path+"/home"} className={classes.linkitem}>
        <ListItemIcon>
          <AppsIcon />
        </ListItemIcon>
        <ListItemText primary="Inicio" />
      </ListItemLink>      
      <ListItemLink button onClick={handleClick} className={classes.linkitem}>
        <ListItemIcon>
          <InboxIcon />
        </ListItemIcon>
        <ListItemText primary="Sistema de Usuarios" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemLink>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemLink className={classes.nested} href={__Uri.path+"/usuarios/admin-asignacion"}>
              <ListItemIcon>
                <StarBorder />
              </ListItemIcon>
              <ListItemText className={classes.linkitem} primary="Gestión de permisos" />        
          </ListItemLink>
          <ListItemLink className={classes.nested} href={__Uri.path+"/usuarios/gestion"}>
              <ListItemIcon>
                <StarBorder />
              </ListItemIcon>
              <ListItemText primary="Gestión de usuarios" />
          </ListItemLink>
          <ListItemLink className={classes.nested} href={__Uri.path+"/usuarios/perfil"} >
            <ListItemIcon>
              <StarBorder />
            </ListItemIcon>
            <ListItemText primary="Crear Usuario" />
          </ListItemLink>
          <ListItemLink className={classes.nested} href={__Uri.path+"/usuarios/usuariodata"}>
            <ListItemIcon>
              <StarBorder />
            </ListItemIcon>
            <ListItemText primary="Crear Rol" />
          </ListItemLink>
        </List>
      </Collapse>
    </List>
  );
}
