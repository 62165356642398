import React from 'react';
import { COLORS, __Uri} from '../../components/general/Constants';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import Titulo from '../../components/general/Titulo'
import PermIdentityIcon from '@material-ui/icons/PermIdentity'
import Subtitulo from '../../components/general/Subtitulo'
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import AddIcon from '@material-ui/icons/Add';

import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import BtnAdd from '../../components/forms/BtnAdd'
import ApiToken from '../../util/ApiToken';
import { withRouter } from 'react-router-dom';
import Swal from 'sweetalert2'

const filter = createFilterOptions();
const __accesID = {
  view : 1,
  config : 3,
};
class AdminAsignacion extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          id : this.props.match.params.hasOwnProperty('id') ? this.props.match.params.id : null,
          valor: null,
          view : false,
          lista_include : [],
          lista_exclude : [],
          lista_selected : [],
        };
      }
    componentWillMount = () => {
      this._fetchGetList()
    }
    _fetchGetList = () => {
      let _Self = this
      ApiToken.request('put','sistema/asignacion/get',{
        keyID : this.state.id
      }, function(res){
        if(res.response){
          let temp = []
          _Self.setState({ lista_include : [...res.data.included] })
          res.data.excluded.forEach((item) => {
            item.lista.forEach((subitem) => {
              temp.push({
                ...subitem,
                title: item.title,
                tipo: item.index
              })
            })
          })
          _Self.setState({lista_exclude : [...temp]})
          _Self.setState({view : true})
        }
      })
    }
    _fetchSaveAsign = () => {
      let _Self = this
      ApiToken.request('post','sistema/asignacion',{
        keyID : this.state.id,
        lista : this.state.lista_selected
      }, function(res){
        if(res.response){
          _Self._fetchGetList()
          _Self.setState({lista_selected : []})
          Swal.fire({text:res.message, icon: 'success', customClass:{
            popup:'bg_dark',
            confirmButton: 'btn_success',
            denyButton:'btn_deny',
            cancelButton:'btn_cancel',
            htmlContainer:'text_white',
            title:'text_white',
          } })
        }else{
          Swal.fire({text:res.message, icon: 'error', customClass:{
            popup:'bg_dark',
            confirmButton: 'btn_success',
            denyButton:'btn_deny',
            cancelButton:'btn_cancel',
            htmlContainer:'text_white',
            title:'text_white',
          } })
        }
      })
    }
    _fetchDeleteItem = (_id, _index) => {
      let _Self = this
      Swal.fire({
        text: '¿Desea eliminar el item seleccionado?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: `Aceptar`,
        cancelButtonText: `Cancelar`,
        customClass:{
            popup:'bg_dark',
            confirmButton: 'btn_success',
            denyButton:'btn_deny',
            cancelButton:'btn_cancel',
            htmlContainer:'text_white',
            title:'text_white',
        }
      }).then((result) => {
          if (result.isConfirmed) {
            ApiToken.request('post','sistema/asignacion/delete',{
              keyID : this.state.id,
              itemID : _id,
              index : _index,
            }, function(res){
              if(res.response){
                _Self._fetchGetList()
                _Self.setState({lista_selected : []})
                Swal.fire( {text:res.message, icon:'success', customClass:{
                  popup:'bg_dark',
                  confirmButton: 'btn_success',
                  denyButton:'btn_deny',
                  cancelButton:'btn_cancel',
                  htmlContainer:'text_white',
                  title:'text_white',
                }})
              }else{
                Swal.fire( {text:res.message, icon:'error', customClass:{
                  popup:'bg_dark',
                  confirmButton: 'btn_success',
                  denyButton:'btn_deny',
                  cancelButton:'btn_cancel',
                  htmlContainer:'text_white',
                  title:'text_white',
                }})
              }
            })
          }
      })
    }
    _fetchGoToPermisions = (_id, _uri) => {
      this.props.history.push(`/sistem-usuarios/asignacion/${this.state.id}/${_uri}/${_id}/permisos`)
    }
    render(){
        const darkTheme = createMuiTheme({
          palette: {
            type: 'dark',
            secondary: {
              light: '#ffffff',
              main: '#788ba5',
              dark: '#546e91',
              contrastText: '#fff',
            }
          },
          secondary: {
              light: '#ffffff',
              main: '#788ba5',
              dark: '#546e91',
              contrastText: '#fff',
            }
        });
        const lista= {
          flexGrow: 1,
          overflow: 'hidden',
          padding: '10px 0',
        };
        const listSection= {
          backgroundColor: 'inherit',
        };
        const ul= {
          backgroundColor: 'inherit',
          padding: 0,
        };
        return this.state.view && (
            <div style={{ padding:'0 10px'}}>
                <div className="contenido max_360">
                    <Titulo text="Asignacion del Sistema"/>
                    <form>
                        <ThemeProvider theme={darkTheme}>
                          <Grid container spacing={1}>
                              <Grid item xs={10}>
                                <Autocomplete
                                    className="input_dark"
                                    multiple
                                    id="tags-outlined"
                                    options={this.state.lista_exclude}
                                    getOptionLabel={(option) => option.nombre}
                                    filterSelectedOptions
                                    groupBy={(option) => option.title}
                                    value = {this.state.lista_selected}
                                    onChange={(event, value) => {
                                      this.setState({lista_selected : [...value]})
                                    }}
                                    renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        label="Añadir miembro"
                                        placeholder="Añadir"
                                        className="input_dark"
                                        color="secondary"
                                    />
                                    )}
                                />
                              </Grid>
                              <Grid item xs={2}>
                                <Button variant="contained" color="secondary" onClick={this._fetchSaveAsign} className="full_content" style={{padding:'15px 0'}} >
                                    <AddIcon/>
                                </Button>
                              </Grid>
                            </Grid>
                            <section className="section_form">
                                <div id="title_section-1">
                                    <PermIdentityIcon style={{ fontSize: 16, color:'#cccccc' }} className="inline" />
                                    <Subtitulo uppercase text="Miembros"/>
                                </div>
                              <List  subheader={<li />}>
                                  <li key={`section-A`} className={listSection}>
                                    <ul className={ul}>
                                      {
                                        this.state.lista_include.map(item => (
                                          <div key={item.tipo}>
                                            <ListSubheader>{item.title}</ListSubheader>
                                            {item.lista.length == 0 && (
                                              <Typography component="div" variant="body1">
                                                <Box color="text.disabled" fontStyle="italic" fontSize={12}>No existen asignaciones.</Box>
                                              </Typography>
                                            )}
                                            {item.lista.map((subitem) => (
                                              <ListItem key={`item-rol-${subitem.id}`} style={{border: 'solid 1px '+COLORS.contrast_white,}}>
                                                <ListItemText onClick={(e)=>{this._fetchGoToPermisions(subitem.keyID, item.key)}} style={{cursor: 'pointer'}} primary={subitem.nombre}/>
                                                <ListItemSecondaryAction>
                                                  <IconButton edge="end" aria-label="delete">
                                                    <DeleteIcon  onClick={(e)=>{this._fetchDeleteItem(subitem.id, item.index)}}/>
                                                  </IconButton>
                                                </ListItemSecondaryAction>
                                              </ListItem>
                                            ))}
                                          </div>
                                        ))
                                      }
                                    </ul>
                                  </li>
                              </List>
                            </section>
                        </ThemeProvider>
                        <BtnAdd/>
                    </form>
                </div>
            </div>
        )

    }
}
export default withRouter(AdminAsignacion);
